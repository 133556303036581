import React from "react"
import "./Boat.css"

export default function Boat() {
    return (
        <svg
            width="763"
            height="295"
            viewBox="0 0 1063 595"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="boat"
        >
            <g id="ship" clipPath="url(#clip0_10_13)">
                <path
                    id="wave2"
                    d="M428.624 467.027C447.325 479.953 465.575 493.749 486.411 502.722C538.005 524.942 597.399 514.579 651.686 500.137C705.974 485.695 761.389 467.345 817.069 474.789C848.643 479.01 878.451 491.363 908.927 500.63C939.404 509.898 972.195 516.104 1002.94 507.783C1033.69 499.462 1061.43 473.077 1062.23 441.233C830.098 398.314 614.885 397.039 428.624 467.027Z"
                    fill="#70FFFA"
                />
                <path
                    id="wave4"
                    d="M140.624 513.027C159.325 525.953 177.575 539.749 198.411 548.722C250.005 570.942 309.399 560.579 363.686 546.137C417.973 531.695 473.389 513.345 529.069 520.789C560.643 525.01 590.451 537.363 620.927 546.63C651.404 555.898 684.195 562.104 714.943 553.783C745.692 545.462 773.426 519.077 774.23 487.233C542.098 444.314 326.885 443.039 140.624 513.027Z"
                    fill="#70FFFA"
                />
                <path
                    id="Vector"
                    d="M452.337 298.868H306.977C304.903 298.87 302.915 299.694 301.449 301.16C299.982 302.626 299.158 304.615 299.157 306.688V359.648C299.158 361.722 299.982 363.71 301.449 365.176C302.915 366.643 304.903 367.467 306.977 367.468H452.337C454.41 367.467 456.398 366.643 457.865 365.176C459.331 363.71 460.155 361.722 460.157 359.648V306.688C460.155 304.615 459.331 302.626 457.865 301.16C456.399 299.694 454.41 298.87 452.337 298.868Z"
                    fill="#E6E6E6"
                    className="boat"
                />
                <path
                    id="Vector_2"
                    d="M313.657 298.868H309.657V367.468H313.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_3"
                    d="M330.657 298.868H326.657V367.468H330.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_4"
                    d="M347.657 298.868H343.657V367.468H347.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_5"
                    d="M364.657 298.868H360.657V367.468H364.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_6"
                    d="M381.657 298.868H377.657V367.468H381.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_7"
                    d="M398.657 298.868H394.657V367.468H398.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_8"
                    d="M415.657 298.868H411.657V367.468H415.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_9"
                    d="M432.657 298.868H428.657V367.468H432.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_10"
                    d="M449.657 298.868H445.657V367.468H449.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_11"
                    d="M809.337 298.868H663.977C661.903 298.87 659.915 299.694 658.449 301.16C656.982 302.626 656.158 304.615 656.157 306.688V359.648C656.158 361.722 656.982 363.71 658.449 365.176C659.915 366.643 661.903 367.467 663.977 367.468H809.337C811.41 367.467 813.398 366.643 814.865 365.176C816.331 363.71 817.155 361.722 817.157 359.648V306.688C817.155 304.615 816.331 302.626 814.865 301.16C813.399 299.694 811.41 298.87 809.337 298.868Z"
                    fill="#2EFFC4"
                    className="boat"
                />
                <path
                    id="Vector_12"
                    d="M670.657 298.868H666.657V367.468H670.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_13"
                    d="M687.657 298.868H683.657V367.468H687.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_14"
                    d="M704.657 298.868H700.657V367.468H704.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_15"
                    d="M721.657 298.868H717.657V367.468H721.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_16"
                    d="M738.657 298.868H734.657V367.468H738.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_17"
                    d="M755.657 298.868H751.657V367.468H755.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_18"
                    d="M772.657 298.868H768.657V367.468H772.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_19"
                    d="M789.657 298.868H785.657V367.468H789.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_20"
                    d="M806.657 298.868H802.657V367.468H806.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_21"
                    d="M647.337 298.868H501.977C499.903 298.87 497.915 299.694 496.449 301.16C494.982 302.626 494.158 304.615 494.157 306.688V359.648C494.158 361.722 494.982 363.71 496.449 365.176C497.915 366.643 499.903 367.467 501.977 367.468H647.337C649.41 367.467 651.398 366.643 652.865 365.176C654.331 363.71 655.155 361.722 655.157 359.648V306.688C655.155 304.615 654.331 302.626 652.865 301.16C651.399 299.694 649.41 298.87 647.337 298.868Z"
                    fill="#E6E6E6"
                    className="boat"
                />
                <path
                    id="Vector_22"
                    d="M508.657 298.868H504.657V367.468H508.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_23"
                    d="M525.657 298.868H521.657V367.468H525.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_24"
                    d="M542.657 298.868H538.657V367.468H542.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_25"
                    d="M559.657 298.868H555.657V367.468H559.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_26"
                    d="M576.657 298.868H572.657V367.468H576.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_27"
                    d="M593.657 298.868H589.657V367.468H593.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_28"
                    d="M610.657 298.868H606.657V367.468H610.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_29"
                    d="M627.657 298.868H623.657V367.468H627.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_30"
                    d="M644.657 298.868H640.657V367.468H644.657V298.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_31"
                    d="M820.337 229.868H674.977C672.903 229.87 670.915 230.694 669.449 232.16C667.982 233.626 667.158 235.615 667.157 237.688V290.648C667.158 292.722 667.982 294.71 669.449 296.176C670.915 297.643 672.903 298.467 674.977 298.468H820.337C822.41 298.467 824.398 297.643 825.865 296.176C827.331 294.71 828.155 292.722 828.157 290.648V237.688C828.155 235.615 827.331 233.626 825.865 232.16C824.399 230.694 822.41 229.87 820.337 229.868Z"
                    fill="#2EFFC4"
                    className="boat"
                />
                <path
                    id="Vector_32"
                    d="M681.657 229.868H677.657V298.468H681.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_33"
                    d="M698.657 229.868H694.657V298.468H698.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_34"
                    d="M715.657 229.868H711.657V298.468H715.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_35"
                    d="M732.657 229.868H728.657V298.468H732.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_36"
                    d="M749.657 229.868H745.657V298.468H749.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_37"
                    d="M766.657 229.868H762.657V298.468H766.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_38"
                    d="M783.657 229.868H779.657V298.468H783.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_39"
                    d="M800.657 229.868H796.657V298.468H800.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_40"
                    d="M817.657 229.868H813.657V298.468H817.657V229.868Z"
                    fill="#CCCCCC"
                    className="boat"
                />
                <path
                    id="Vector_41"
                    d="M777.337 303.868H631.977C629.903 303.87 627.915 304.694 626.449 306.16C624.982 307.626 624.158 309.615 624.157 311.688V364.648C624.158 366.722 624.982 368.71 626.449 370.176C627.915 371.643 629.903 372.467 631.977 372.468H777.337C779.41 372.467 781.398 371.643 782.865 370.176C784.331 368.71 785.155 366.722 785.157 364.648V311.688C785.155 309.615 784.331 307.626 782.865 306.16C781.399 304.694 779.41 303.87 777.337 303.868Z"
                    fill="#6B95FF"
                    className="boat"
                />
                <path
                    id="Vector_42"
                    d="M638.657 303.868H634.657V372.468H638.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_43"
                    d="M655.657 303.868H651.657V372.468H655.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_44"
                    d="M672.657 303.868H668.657V372.468H672.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_45"
                    d="M689.657 303.868H685.657V372.468H689.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_46"
                    d="M706.657 303.868H702.657V372.468H706.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_47"
                    d="M723.657 303.868H719.657V372.468H723.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_48"
                    d="M740.657 303.868H736.657V372.468H740.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_49"
                    d="M757.657 303.868H753.657V372.468H757.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_50"
                    d="M774.657 303.868H770.657V372.468H774.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_51"
                    d="M594.337 303.868H448.977C446.903 303.87 444.915 304.694 443.449 306.16C441.982 307.626 441.158 309.615 441.157 311.688V364.648C441.158 366.722 441.982 368.71 443.449 370.176C444.915 371.643 446.903 372.467 448.977 372.468H594.337C596.41 372.467 598.398 371.643 599.865 370.176C601.331 368.71 602.155 366.722 602.157 364.648V311.688C602.155 309.615 601.331 307.626 599.865 306.16C598.399 304.694 596.41 303.87 594.337 303.868Z"
                    fill="#00A8D6"
                    className="boat"
                />
                <path
                    id="Vector_52"
                    d="M455.657 303.868H451.657V372.468H455.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_53"
                    d="M472.657 303.868H468.657V372.468H472.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_54"
                    d="M489.657 303.868H485.657V372.468H489.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_55"
                    d="M506.657 303.868H502.657V372.468H506.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_56"
                    d="M523.657 303.868H519.657V372.468H523.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_57"
                    d="M540.657 303.868H536.657V372.468H540.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_58"
                    d="M557.657 303.868H553.657V372.468H557.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_59"
                    d="M574.657 303.868H570.657V372.468H574.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_60"
                    d="M591.657 303.868H587.657V372.468H591.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_61"
                    d="M649.337 234.868H503.977C501.903 234.87 499.915 235.694 498.449 237.16C496.982 238.626 496.158 240.615 496.157 242.688V295.648C496.158 297.722 496.982 299.71 498.449 301.176C499.915 302.643 501.903 303.467 503.977 303.468H649.337C651.41 303.467 653.398 302.643 654.865 301.176C656.331 299.71 657.155 297.722 657.157 295.648V242.688C657.155 240.615 656.331 238.626 654.865 237.16C653.399 235.694 651.41 234.87 649.337 234.868Z"
                    fill="#6B95FF"
                    className="boat"
                />
                <path
                    id="Vector_62"
                    d="M510.657 234.868H506.657V303.468H510.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_63"
                    d="M527.657 234.868H523.657V303.468H527.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_64"
                    d="M544.657 234.868H540.657V303.468H544.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_65"
                    d="M561.657 234.868H557.657V303.468H561.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_66"
                    d="M578.657 234.868H574.657V303.468H578.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_67"
                    d="M595.657 234.868H591.657V303.468H595.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_68"
                    d="M612.657 234.868H608.657V303.468H612.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_69"
                    d="M629.657 234.868H625.657V303.468H629.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_70"
                    d="M646.657 234.868H642.657V303.468H646.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_71"
                    d="M399.337 303.868H253.977C251.903 303.87 249.915 304.694 248.449 306.16C246.982 307.626 246.158 309.615 246.157 311.688V364.648C246.158 366.722 246.982 368.71 248.449 370.176C249.915 371.643 251.903 372.467 253.977 372.468H399.337C401.41 372.467 403.398 371.643 404.865 370.176C406.331 368.71 407.155 366.722 407.157 364.648V311.688C407.155 309.615 406.331 307.626 404.865 306.16C403.398 304.694 401.41 303.87 399.337 303.868Z"
                    fill="#6B95FF"
                    className="boat"
                />
                <path
                    id="Vector_72"
                    d="M260.657 303.868H256.657V372.468H260.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_73"
                    d="M277.657 303.868H273.657V372.468H277.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_74"
                    d="M294.657 303.868H290.657V372.468H294.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_75"
                    d="M311.657 303.868H307.657V372.468H311.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_76"
                    d="M328.657 303.868H324.657V372.468H328.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_77"
                    d="M345.657 303.868H341.657V372.468H345.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_78"
                    d="M362.657 303.868H358.657V372.468H362.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_79"
                    d="M379.657 303.868H375.657V372.468H379.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_80"
                    d="M396.657 303.868H392.657V372.468H396.657V303.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_81"
                    d="M435.337 234.868H289.977C287.903 234.87 285.915 235.694 284.449 237.16C282.982 238.626 282.158 240.615 282.157 242.688V295.648C282.158 297.722 282.982 299.71 284.449 301.176C285.915 302.643 287.903 303.467 289.977 303.468H435.337C437.41 303.467 439.398 302.643 440.865 301.176C442.331 299.71 443.155 297.722 443.157 295.648V242.688C443.155 240.615 442.331 238.626 440.865 237.16C439.399 235.694 437.41 234.87 435.337 234.868Z"
                    fill="#00A8D6"
                    className="boat"
                />
                <path
                    id="Vector_82"
                    d="M296.657 234.868H292.657V303.468H296.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_83"
                    d="M313.657 234.868H309.657V303.468H313.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_84"
                    d="M330.657 234.868H326.657V303.468H330.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_85"
                    d="M347.657 234.868H343.657V303.468H347.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_86"
                    d="M364.657 234.868H360.657V303.468H364.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_87"
                    d="M381.657 234.868H377.657V303.468H381.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_88"
                    d="M398.657 234.868H394.657V303.468H398.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_89"
                    d="M415.657 234.868H411.657V303.468H415.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="Vector_90"
                    d="M432.657 234.868H428.657V303.468H432.657V234.868Z"
                    fill="#3F3D56"
                    className="boat"
                />
                <path
                    id="boat"
                    d="M905.313 376.518C905.073 375.654 904.763 374.81 904.386 373.995L886.54 335.599C885.231 332.782 883.144 330.398 880.524 328.727C877.905 327.056 874.864 326.168 871.757 326.168H798.556C795.45 326.168 792.408 327.056 789.789 328.727C787.17 330.398 785.083 332.782 783.773 335.599L769.1 367.168H350.748L336.54 336.599C335.231 333.782 333.143 331.398 330.524 329.727C327.905 328.056 324.863 327.168 321.757 327.168H248.556C245.747 327.17 242.985 327.898 240.541 329.283L228.233 307.305C227.421 305.854 226.15 304.713 224.619 304.062C223.089 303.41 221.386 303.285 219.776 303.705C218.167 304.125 216.742 305.066 215.725 306.383C214.708 307.699 214.157 309.315 214.157 310.979V483.586C214.157 486.157 214.663 488.704 215.647 491.08C216.631 493.456 218.074 495.614 219.892 497.433C221.711 499.251 223.869 500.694 226.245 501.678C228.621 502.662 231.168 503.168 233.739 503.168H793.917C796.525 503.168 799.108 502.647 801.512 501.636C803.916 500.624 806.094 499.142 807.918 497.277L902.616 400.443C905.693 397.341 907.633 393.291 908.122 388.949C908.612 384.608 907.622 380.228 905.313 376.518Z"
                    fill="#2C4349"
                />
                <path
                    id="Vector_91"
                    d="M806.755 364.668H797.558C795.596 364.666 793.715 363.886 792.327 362.498C790.939 361.111 790.159 359.229 790.157 357.267V347.07C790.159 345.108 790.939 343.226 792.327 341.839C793.715 340.451 795.596 339.671 797.558 339.668H806.755C808.718 339.671 810.599 340.451 811.986 341.839C813.374 343.226 814.154 345.108 814.157 347.07V357.267C814.154 359.229 813.374 361.111 811.986 362.498C810.599 363.886 808.718 364.666 806.755 364.668Z"
                    fill="white"
                    className="boat"
                />
                <path
                    id="Vector_92"
                    d="M839.755 364.668H830.558C828.596 364.666 826.715 363.886 825.327 362.498C823.939 361.111 823.159 359.229 823.157 357.267V347.07C823.159 345.108 823.939 343.226 825.327 341.839C826.715 340.451 828.596 339.671 830.558 339.668H839.755C841.718 339.671 843.599 340.451 844.986 341.839C846.374 343.226 847.154 345.108 847.157 347.07V357.267C847.154 359.229 846.374 361.111 844.986 362.498C843.599 363.886 841.718 364.666 839.755 364.668Z"
                    fill="white"
                    className="boat"
                />
                <path
                    id="Vector_93"
                    d="M872.755 364.668H863.558C861.596 364.666 859.715 363.886 858.327 362.498C856.939 361.111 856.159 359.229 856.157 357.267V347.07C856.159 345.108 856.939 343.226 858.327 341.839C859.715 340.451 861.596 339.671 863.558 339.668H872.755C874.718 339.671 876.599 340.451 877.986 341.839C879.374 343.226 880.154 345.108 880.157 347.07V357.267C880.154 359.229 879.374 361.111 877.986 362.498C876.599 363.886 874.718 364.666 872.755 364.668Z"
                    fill="white"
                    className="boat"
                />
                <path
                    id="Vector_97"
                    d="M256.755 365.668H247.558C245.596 365.666 243.714 364.886 242.327 363.498C240.939 362.111 240.159 360.229 240.157 358.267V348.07C240.159 346.108 240.939 344.226 242.327 342.839C243.714 341.451 245.596 340.671 247.558 340.668H256.755C258.718 340.671 260.599 341.451 261.986 342.839C263.374 344.226 264.154 346.108 264.157 348.07V358.267C264.154 360.229 263.374 362.111 261.986 363.498C260.599 364.886 258.718 365.666 256.755 365.668Z"
                    fill="white"
                    className="boat"
                />
                <path
                    id="Vector_98"
                    d="M289.755 365.668H280.558C278.596 365.666 276.714 364.886 275.327 363.498C273.939 362.111 273.159 360.229 273.157 358.267V348.07C273.159 346.108 273.939 344.226 275.327 342.839C276.714 341.451 278.596 340.671 280.558 340.668H289.755C291.718 340.671 293.599 341.451 294.986 342.839C296.374 344.226 297.154 346.108 297.157 348.07V358.267C297.154 360.229 296.374 362.111 294.986 363.498C293.599 364.886 291.718 365.666 289.755 365.668Z"
                    fill="white"
                    className="boat"
                />
                <path
                    id="Vector_99"
                    d="M322.755 365.668H313.558C311.596 365.666 309.714 364.886 308.327 363.498C306.939 362.111 306.159 360.229 306.157 358.267V348.07C306.159 346.108 306.939 344.226 308.327 342.839C309.714 341.451 311.596 340.671 313.558 340.668H322.755C324.718 340.671 326.599 341.451 327.986 342.839C329.374 344.226 330.154 346.108 330.157 348.07V358.267C330.154 360.229 329.374 362.111 327.986 363.498C326.599 364.886 324.718 365.666 322.755 365.668Z"
                    fill="white"
                    className="boat"
                />
                <path
                    id="wave3"
                    d="M199.68 470.983C173.774 470.363 146.002 484.879 138.631 509.721L279.255 503.996C276.595 502.158 273.924 500.343 271.241 498.55C249.664 484.199 225.585 471.603 199.68 470.983Z"
                    fill="#42A7FF"
                />
                <path
                    id="wave1"
                    d="M279.255 503.996C297.955 516.923 316.205 530.718 337.042 539.692C388.636 561.911 448.029 551.549 502.317 537.107C556.604 522.665 612.02 504.315 667.7 511.758C699.273 515.979 729.082 528.332 759.558 537.6C790.034 546.867 822.825 553.073 853.574 544.752C884.322 536.432 912.057 510.047 912.861 478.203C680.728 435.283 465.515 434.009 279.255 503.996Z"
                    fill="#42A7FF"
                />
            </g>
            <defs>
                <clipPath id="clip0_10_13">
                    <rect width="1062.23" height="595" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
