/**
 * MathQuill v0.10.1, by Han, Jeanine, and Mary
 * http://mathquill.com | maintainers@mathquill.com
 *
 * This Source Code Form is subject to the terms of the
 * Mozilla Public License, v. 2.0. If a copy of the MPL
 * was not distributed with this file, You can obtain
 * one at http://mozilla.org/MPL/2.0/.
 */
!(function () {
    function t() {}
    function e(t) {
        var e = t.length - 1
        return function () {
            var n = g.call(arguments, 0, e),
                i = g.call(arguments, e)
            return t.apply(this, n.concat([i]))
        }
    }
    function n(t) {
        return e(function (e, n) {
            "function" != typeof e && (e = b(e))
            var i = function (t) {
                return e.apply(t, [t].concat(n))
            }
            return t.call(this, i)
        })
    }
    function i(t) {
        var e = g.call(arguments, 1)
        return function () {
            return t.apply(this, e)
        }
    }
    function s(t, e) {
        if (!e) throw new Error("prayer failed: " + t)
    }
    function r(t) {
        s("a direction was passed", t === w || t === x)
    }
    function o(t, e, n) {
        s("a parent is always present", t),
            s(
                "leftward is properly set up",
                (function () {
                    return e ? e[x] === n && e.parent === t : t.ends[w] === n
                })()
            ),
            s(
                "rightward is properly set up",
                (function () {
                    return n ? n[w] === e && n.parent === t : t.ends[x] === e
                })()
            )
    }
    function a() {
        window.console &&
            console.warn(
                'You are using the MathQuill API without specifying an interface version, which will fail in v1.0.0. Easiest fix is to do the following before doing anything else:\n\n    MathQuill = MathQuill.getInterface(1);\n    // now MathQuill.MathField() works like it used to\n\nSee also the "`dev` branch (2014–2015) → v0.10.0 Migration Guide" at\n  https://github.com/mathquill/mathquill/wiki/%60dev%60-branch-(2014%E2%80%932015)-%E2%86%92-v0.10.0-Migration-Guide'
            )
    }
    function l(t) {
        return a(), It(t)
    }
    function c(e) {
        function n(t) {
            var e, n
            return t && t.nodeType
                ? ((e = q(t).children(".mq-root-block").attr(Ft)),
                  (n = e && O.byId[e].controller),
                  n ? s[n.KIND_OF_MQ](n) : null)
                : null
        }
        function i(t, e) {
            var n, i, r
            e && e.handlers && (e.handlers = { fns: e.handlers, APIClasses: s })
            for (n in e)
                e.hasOwnProperty(n) &&
                    ((i = e[n]), (r = E[n]), (t[n] = r ? r(i) : i))
        }
        var s, r, o
        if (!(z <= e && e <= I))
            throw (
                "Only interface versions between " +
                z +
                " and " +
                I +
                " supported. You specified: " +
                e
            )
        ;(s = {}),
            (n.L = w),
            (n.R = x),
            (n.saneKeyboardEvents = B),
            (n.config = function (t) {
                return i(S.p, t), this
            }),
            (n.registerEmbed = function (t, e) {
                if (!/^[a-z][a-z0-9]*$/i.test(t))
                    throw "Embed name must start with letter and be only letters and digits"
                L[t] = e
            }),
            (r = s.AbstractMathQuill =
                v(A, function (t) {
                    ;(t.init = function (t) {
                        ;(this.__controller = t),
                            (this.__options = t.options),
                            (this.id = t.id),
                            (this.data = t.data)
                    }),
                        (t.__mathquillify = function (t) {
                            var e,
                                n = this.__controller,
                                i = n.root,
                                s = n.container
                            n.createTextarea(),
                                (e = s.addClass(t).contents().detach()),
                                (i.jQ = q('<span class="mq-root-block"/>')
                                    .attr(Ft, i.id)
                                    .appendTo(s)),
                                this.latex(e.text()),
                                (this.revert = function () {
                                    return s
                                        .empty()
                                        .unbind(".mathquill")
                                        .removeClass(
                                            "mq-editable-field mq-math-mode mq-text-mode"
                                        )
                                        .append(e)
                                })
                        }),
                        (t.config = function (t) {
                            return i(this.__options, t), this
                        }),
                        (t.el = function () {
                            return this.__controller.container[0]
                        }),
                        (t.text = function () {
                            return this.__controller.exportText()
                        }),
                        (t.latex = function (t) {
                            return arguments.length > 0
                                ? (this.__controller.renderLatexMath(t),
                                  this.__controller.blurred &&
                                      this.__controller.cursor
                                          .hide()
                                          .parent.blur(),
                                  this)
                                : this.__controller.exportLatex()
                        }),
                        (t.html = function () {
                            return this.__controller.root.jQ
                                .html()
                                .replace(
                                    / mathquill-(?:command|block)-id="?\d+"?/g,
                                    ""
                                )
                                .replace(
                                    /<span class="?mq-cursor( mq-blink)?"?>.?<\/span>/i,
                                    ""
                                )
                                .replace(/ mq-hasCursor|mq-hasCursor ?/, "")
                                .replace(/ class=(""|(?= |>))/g, "")
                        }),
                        (t.reflow = function () {
                            return (
                                this.__controller.root.postOrder("reflow"), this
                            )
                        })
                })),
            (n.prototype = r.prototype),
            (s.EditableField = v(r, function (e, n) {
                ;(e.__mathquillify = function () {
                    return (
                        n.__mathquillify.apply(this, arguments),
                        (this.__controller.editable = !0),
                        this.__controller.delegateMouseEvents(),
                        this.__controller.editablesTextareaEvents(),
                        this
                    )
                }),
                    (e.focus = function () {
                        return this.__controller.textarea.focus(), this
                    }),
                    (e.blur = function () {
                        return this.__controller.textarea.blur(), this
                    }),
                    (e.write = function (t) {
                        return (
                            this.__controller.writeLatex(t),
                            this.__controller.scrollHoriz(),
                            this.__controller.blurred &&
                                this.__controller.cursor.hide().parent.blur(),
                            this
                        )
                    }),
                    (e.empty = function () {
                        var t = this.__controller.root,
                            e = this.__controller.cursor
                        return (
                            t.eachChild("postOrder", "dispose"),
                            (t.ends[w] = t.ends[x] = 0),
                            t.jQ.empty(),
                            delete e.selection,
                            e.insAtRightEnd(t),
                            this
                        )
                    }),
                    (e.cmd = function (t) {
                        var e,
                            n = this.__controller.notify(),
                            i = n.cursor
                        return (
                            /^\\[a-z]+$/i.test(t) && !i.isTooDeep()
                                ? ((t = t.slice(1)),
                                  (e = j[t] || bt[t]) &&
                                      ((t = e(t)),
                                      i.selection &&
                                          t.replaces(i.replaceSelection()),
                                      t.createLeftOf(i.show()),
                                      this.__controller.scrollHoriz()))
                                : i.parent.write(i, t),
                            n.blurred && i.hide().parent.blur(),
                            this
                        )
                    }),
                    (e.select = function () {
                        var t = this.__controller
                        for (
                            t.notify("move").cursor.insAtRightEnd(t.root);
                            t.cursor[w];

                        )
                            t.selectLeft()
                        return this
                    }),
                    (e.clearSelection = function () {
                        return this.__controller.cursor.clearSelection(), this
                    }),
                    (e.moveToDirEnd = function (t) {
                        return (
                            this.__controller
                                .notify("move")
                                .cursor.insAtDirEnd(t, this.__controller.root),
                            this
                        )
                    }),
                    (e.moveToLeftEnd = function () {
                        return this.moveToDirEnd(w)
                    }),
                    (e.moveToRightEnd = function () {
                        return this.moveToDirEnd(x)
                    }),
                    (e.keystroke = function (e) {
                        var n
                        for (
                            e = e.replace(/^\s+|\s+$/g, "").split(/\s+/), n = 0;
                            n < e.length;
                            n += 1
                        )
                            this.__controller.keystroke(e[n], {
                                preventDefault: t,
                            })
                        return this
                    }),
                    (e.typedText = function (t) {
                        for (var e = 0; e < t.length; e += 1)
                            this.__controller.typedText(t.charAt(e))
                        return this
                    }),
                    (e.dropEmbedded = function (t, e, n) {
                        var i,
                            s = t - q(window).scrollLeft(),
                            r = e - q(window).scrollTop(),
                            o = document.elementFromPoint(s, r)
                        this.__controller.seek(q(o), t, e),
                            (i = gt().setOptions(n)),
                            i.createLeftOf(this.__controller.cursor)
                    }),
                    (e.clickAt = function (t, e, n) {
                        n = n || document.elementFromPoint(t, e)
                        var i = this.__controller,
                            s = i.root
                        return (
                            Bt.contains(s.jQ[0], n) || (n = s.jQ[0]),
                            i.seek(q(n), t + pageXOffset, e + pageYOffset),
                            i.blurred && this.focus(),
                            this
                        )
                    }),
                    (e.ignoreNextMousedown = function (t) {
                        return (
                            (this.__controller.cursor.options.ignoreNextMousedown =
                                t),
                            this
                        )
                    })
            })),
            (n.EditableField = function () {
                throw "wtf don't call me, I'm 'abstract'"
            }),
            (n.EditableField.prototype = s.EditableField.prototype)
        for (o in D)
            !(function (t, i) {
                var r = (s[t] = i(s))
                ;(n[t] = function (i, s) {
                    var o,
                        a = n(i)
                    return a instanceof r || !i || !i.nodeType
                        ? a
                        : ((o = C(r.RootBlock(), q(i), S())),
                          (o.KIND_OF_MQ = t),
                          r(o).__mathquillify(s, e))
                }),
                    (n[t].prototype = r.prototype)
            })(o, D[o])
        return n
    }
    function h(t) {
        var e,
            n = "moveOutOf deleteOutOf selectOutOf upOutOf downOutOf".split(" ")
        for (e = 0; e < n.length; e += 1)
            !(function (e) {
                t[e] = function (t) {
                    this.controller.handle(e, t)
                }
            })(n[e])
        t.reflow = function () {
            this.controller.handle("reflow"),
                this.controller.handle("edited"),
                this.controller.handle("edit")
        }
    }
    function u(t, e, n) {
        return v(G, {
            ctrlSeq: t,
            htmlTemplate: "<" + e + " " + n + ">&0</" + e + ">",
        })
    }
    function f(t) {
        var e = this.parent,
            n = t
        do {
            if (n[x]) return t.insLeftOf(e)
            n = n.parent.parent
        } while (n !== e)
        t.insRightOf(e)
    }
    function p(t, e) {
        ;(t.jQadd = function () {
            e.jQadd.apply(this, arguments),
                (this.delimjQs = this.jQ
                    .children(":first")
                    .add(this.jQ.children(":last"))),
                (this.contentjQ = this.jQ.children(":eq(1)"))
        }),
            (t.reflow = function () {
                var t =
                    this.contentjQ.outerHeight() /
                    parseFloat(this.contentjQ.css("fontSize"))
                X(this.delimjQs, Nt(1 + 0.2 * (t - 1), 1.2), 1.2 * t)
            })
    }
    function d(t, e) {
        var e = e || t,
            n = dt[t],
            s = dt[e]
        ;(Q[t] = i(pt, w, t, n, e, s)), (Q[n] = i(pt, x, t, n, e, s))
    }
    var m,
        g,
        b,
        v,
        w,
        x,
        q,
        y,
        O,
        k,
        j,
        Q,
        T,
        _,
        C,
        D,
        S,
        E,
        A,
        L,
        z,
        I,
        R,
        B,
        M,
        F,
        N,
        P,
        $,
        W,
        U,
        V,
        H,
        G,
        K,
        Z,
        Y,
        X,
        J,
        tt,
        et,
        nt,
        it,
        st,
        rt,
        ot,
        at,
        lt,
        ct,
        ht,
        ut,
        ft,
        pt,
        dt,
        mt,
        gt,
        bt,
        vt,
        wt,
        xt,
        qt,
        yt,
        Ot,
        kt,
        jt,
        Qt,
        Tt,
        _t,
        Ct,
        Dt,
        St,
        Et,
        At,
        Lt,
        zt,
        It,
        Rt,
        Bt = window.jQuery,
        Mt = "mathquill-command-id",
        Ft = "mathquill-block-id",
        Nt = Math.min,
        Pt = Math.max
    if (!Bt) throw "MathQuill requires jQuery 1.5.2+ to be loaded first"
    ;(g = [].slice),
        (b = e(function (t, n) {
            return e(function (e, i) {
                if (t in e) return e[t].apply(e, n.concat(i))
            })
        })),
        (v = (function (t, e, n) {
            function i(t) {
                return "object" == typeof t
            }
            function s(t) {
                return "function" == typeof t
            }
            function r() {}
            return function t(n, o) {
                function a() {
                    var t = new l()
                    return s(t.init) && t.init.apply(t, arguments), t
                }
                function l() {}
                var c, h, u
                return (
                    void 0 === o && ((o = n), (n = Object)),
                    (a.Bare = l),
                    (c = r.prototype = n.prototype),
                    (h = l.prototype = a.prototype = a.p = new r()),
                    (h.constructor = a),
                    (a.mixin = function (e) {
                        return (
                            (l.prototype = a.prototype = t(a, e).prototype), a
                        )
                    }),
                    (a.open = function (t) {
                        if (
                            ((u = {}),
                            s(t)
                                ? (u = t.call(a, h, c, a, n))
                                : i(t) && (u = t),
                            i(u))
                        )
                            for (var r in u) e.call(u, r) && (h[r] = u[r])
                        return s(h.init) || (h.init = n), a
                    })(o)
                )
            }
        })(0, {}.hasOwnProperty)),
        (w = -1),
        (x = 1),
        (q = v(Bt, function (t) {
            ;(t.insDirOf = function (t, e) {
                return t === w
                    ? this.insertBefore(e.first())
                    : this.insertAfter(e.last())
            }),
                (t.insAtDirEnd = function (t, e) {
                    return t === w ? this.prependTo(e) : this.appendTo(e)
                })
        })),
        (y = v(function (t) {
            ;(t.parent = 0),
                (t[w] = 0),
                (t[x] = 0),
                (t.init = function (t, e, n) {
                    ;(this.parent = t), (this[w] = e), (this[x] = n)
                }),
                (this.copy = function (t) {
                    return y(t.parent, t[w], t[x])
                })
        })),
        (O = v(function (t) {
            function e() {
                return (i += 1)
            }
            ;(t[w] = 0), (t[x] = 0), (t.parent = 0)
            var i = 0
            ;(this.byId = {}),
                (t.init = function () {
                    ;(this.id = e()),
                        (O.byId[this.id] = this),
                        (this.ends = {}),
                        (this.ends[w] = 0),
                        (this.ends[x] = 0)
                }),
                (t.dispose = function () {
                    delete O.byId[this.id]
                }),
                (t.toString = function () {
                    return "{{ MathQuill Node #" + this.id + " }}"
                }),
                (t.jQ = q()),
                (t.jQadd = function (t) {
                    return (this.jQ = this.jQ.add(t))
                }),
                (t.jQize = function (t) {
                    function e(t) {
                        var n, i
                        for (
                            t.getAttribute &&
                                ((n = t.getAttribute("mathquill-command-id")),
                                (i = t.getAttribute("mathquill-block-id")),
                                n && O.byId[n].jQadd(t),
                                i && O.byId[i].jQadd(t)),
                                t = t.firstChild;
                            t;
                            t = t.nextSibling
                        )
                            e(t)
                    }
                    var n
                    for (t = q(t || this.html()), n = 0; n < t.length; n += 1)
                        e(t[n])
                    return t
                }),
                (t.createDir = function (t, e) {
                    r(t)
                    var n = this
                    return (
                        n.jQize(),
                        n.jQ.insDirOf(t, e.jQ),
                        (e[t] = n.adopt(e.parent, e[w], e[x])),
                        n
                    )
                }),
                (t.createLeftOf = function (t) {
                    return this.createDir(w, t)
                }),
                (t.selectChildren = function (t, e) {
                    return _(t, e)
                }),
                (t.bubble = n(function (t) {
                    var e
                    for (e = this; e && !1 !== t(e); e = e.parent);
                    return this
                })),
                (t.postOrder = n(function (t) {
                    return (
                        (function e(n) {
                            n.eachChild(e), t(n)
                        })(this),
                        this
                    )
                })),
                (t.isEmpty = function () {
                    return 0 === this.ends[w] && 0 === this.ends[x]
                }),
                (t.isStyleBlock = function () {
                    return !1
                }),
                (t.children = function () {
                    return k(this.ends[w], this.ends[x])
                }),
                (t.eachChild = function () {
                    var t = this.children()
                    return t.each.apply(t, arguments), this
                }),
                (t.foldChildren = function (t, e) {
                    return this.children().fold(t, e)
                }),
                (t.withDirAdopt = function (t, e, n, i) {
                    return k(this, this).withDirAdopt(t, e, n, i), this
                }),
                (t.adopt = function (t, e, n) {
                    return k(this, this).adopt(t, e, n), this
                }),
                (t.disown = function () {
                    return k(this, this).disown(), this
                }),
                (t.remove = function () {
                    return (
                        this.jQ.remove(),
                        this.postOrder("dispose"),
                        this.disown()
                    )
                })
        })),
        (k = v(function (t) {
            ;(t.init = function (t, e, n) {
                if (
                    (n === m && (n = w),
                    r(n),
                    s("no half-empty fragments", !t == !e),
                    (this.ends = {}),
                    t)
                ) {
                    s("withDir is passed to Fragment", t instanceof O),
                        s("oppDir is passed to Fragment", e instanceof O),
                        s(
                            "withDir and oppDir have the same parent",
                            t.parent === e.parent
                        ),
                        (this.ends[n] = t),
                        (this.ends[-n] = e)
                    var i = this.fold([], function (t, e) {
                        return t.push.apply(t, e.jQ.get()), t
                    })
                    this.jQ = this.jQ.add(i)
                }
            }),
                (t.jQ = q()),
                (t.withDirAdopt = function (t, e, n, i) {
                    return t === w ? this.adopt(e, n, i) : this.adopt(e, i, n)
                }),
                (t.adopt = function (t, e, n) {
                    var i, s, r
                    return (
                        o(t, e, n),
                        (i = this),
                        (i.disowned = !1),
                        (s = i.ends[w])
                            ? ((r = i.ends[x]),
                              e || (t.ends[w] = s),
                              n ? (n[w] = r) : (t.ends[x] = r),
                              (i.ends[x][x] = n),
                              i.each(function (n) {
                                  ;(n[w] = e),
                                      (n.parent = t),
                                      e && (e[x] = n),
                                      (e = n)
                              }),
                              i)
                            : this
                    )
                }),
                (t.disown = function () {
                    var t,
                        e,
                        n = this,
                        i = n.ends[w]
                    return !i || n.disowned
                        ? n
                        : ((n.disowned = !0),
                          (t = n.ends[x]),
                          (e = i.parent),
                          o(e, i[w], i),
                          o(e, t, t[x]),
                          i[w] ? (i[w][x] = t[x]) : (e.ends[w] = t[x]),
                          t[x] ? (t[x][w] = i[w]) : (e.ends[x] = i[w]),
                          n)
                }),
                (t.remove = function () {
                    return (
                        this.jQ.remove(),
                        this.each("postOrder", "dispose"),
                        this.disown()
                    )
                }),
                (t.each = n(function (t) {
                    var e = this,
                        n = e.ends[w]
                    if (!n) return e
                    for (; n !== e.ends[x][x] && !1 !== t(n); n = n[x]);
                    return e
                })),
                (t.fold = function (t, e) {
                    return (
                        this.each(function (n) {
                            t = e.call(this, t, n)
                        }),
                        t
                    )
                })
        })),
        (j = {}),
        (Q = {}),
        (T = v(y, function (t) {
            ;(t.init = function (t, e) {
                ;(this.parent = t), (this.options = e)
                var n =
                    (this.jQ =
                    this._jQ =
                        q('<span class="mq-cursor">&#8203;</span>'))
                ;(this.blink = function () {
                    n.toggleClass("mq-blink")
                }),
                    (this.upDownCache = {})
            }),
                (t.show = function () {
                    return (
                        (this.jQ = this._jQ.removeClass("mq-blink")),
                        "intervalId" in this
                            ? clearInterval(this.intervalId)
                            : (this[x]
                                  ? this.selection &&
                                    this.selection.ends[w][w] === this[w]
                                      ? this.jQ.insertBefore(this.selection.jQ)
                                      : this.jQ.insertBefore(this[x].jQ.first())
                                  : this.jQ.appendTo(this.parent.jQ),
                              this.parent.focus()),
                        (this.intervalId = setInterval(this.blink, 500)),
                        this
                    )
                }),
                (t.hide = function () {
                    return (
                        "intervalId" in this && clearInterval(this.intervalId),
                        delete this.intervalId,
                        this.jQ.detach(),
                        (this.jQ = q()),
                        this
                    )
                }),
                (t.withDirInsertAt = function (t, e, n, i) {
                    var s = this.parent
                    ;(this.parent = e),
                        (this[t] = n),
                        (this[-t] = i),
                        s !== e && s.blur && s.blur(this)
                }),
                (t.insDirOf = function (t, e) {
                    return (
                        r(t),
                        this.jQ.insDirOf(t, e.jQ),
                        this.withDirInsertAt(t, e.parent, e[t], e),
                        this.parent.jQ.addClass("mq-hasCursor"),
                        this
                    )
                }),
                (t.insLeftOf = function (t) {
                    return this.insDirOf(w, t)
                }),
                (t.insRightOf = function (t) {
                    return this.insDirOf(x, t)
                }),
                (t.insAtDirEnd = function (t, e) {
                    return (
                        r(t),
                        this.jQ.insAtDirEnd(t, e.jQ),
                        this.withDirInsertAt(t, e, 0, e.ends[t]),
                        e.focus(),
                        this
                    )
                }),
                (t.insAtLeftEnd = function (t) {
                    return this.insAtDirEnd(w, t)
                }),
                (t.insAtRightEnd = function (t) {
                    return this.insAtDirEnd(x, t)
                }),
                (t.jumpUpDown = function (t, e) {
                    var n,
                        i,
                        s = this
                    ;(s.upDownCache[t.id] = y.copy(s)),
                        (n = s.upDownCache[e.id]),
                        n
                            ? n[x]
                                ? s.insLeftOf(n[x])
                                : s.insAtRightEnd(n.parent)
                            : ((i = s.offset().left), e.seek(i, s))
                }),
                (t.offset = function () {
                    var t = this,
                        e = t.jQ.removeClass("mq-cursor").offset()
                    return t.jQ.addClass("mq-cursor"), e
                }),
                (t.unwrapGramp = function () {
                    var t = this.parent.parent,
                        e = t.parent,
                        n = t[x],
                        i = this,
                        s = t[w]
                    if (
                        (t.disown().eachChild(function (i) {
                            i.isEmpty() ||
                                (i
                                    .children()
                                    .adopt(e, s, n)
                                    .each(function (e) {
                                        e.jQ.insertBefore(t.jQ.first())
                                    }),
                                (s = i.ends[x]))
                        }),
                        !this[x])
                    )
                        if (this[w]) this[x] = this[w][x]
                        else
                            for (; !this[x]; ) {
                                if (
                                    ((this.parent = this.parent[x]),
                                    !this.parent)
                                ) {
                                    ;(this[x] = t[x]), (this.parent = e)
                                    break
                                }
                                this[x] = this.parent.ends[w]
                            }
                    this[x] ? this.insLeftOf(this[x]) : this.insAtRightEnd(e),
                        t.jQ.remove(),
                        t[w].siblingDeleted &&
                            t[w].siblingDeleted(i.options, x),
                        t[x].siblingDeleted && t[x].siblingDeleted(i.options, w)
                }),
                (t.startSelection = function () {
                    var t,
                        e = (this.anticursor = y.copy(this)),
                        n = (e.ancestors = {})
                    for (t = e; t.parent; t = t.parent) n[t.parent.id] = t
                }),
                (t.endSelection = function () {
                    delete this.anticursor
                }),
                (t.select = function () {
                    var t,
                        e,
                        n,
                        i,
                        r,
                        o,
                        a,
                        l = this.anticursor
                    if (this[w] === l[w] && this.parent === l.parent) return !1
                    for (t = this; t.parent; t = t.parent)
                        if (t.parent.id in l.ancestors) {
                            e = t.parent
                            break
                        }
                    if (
                        (s("cursor and anticursor in the same tree", e),
                        (n = l.ancestors[e.id]),
                        (o = x),
                        t[w] !== n)
                    )
                        for (a = t; a; a = a[x])
                            if (a[x] === n[x]) {
                                ;(o = w), (i = t), (r = n)
                                break
                            }
                    return (
                        o === x && ((i = n), (r = t)),
                        i instanceof y && (i = i[x]),
                        r instanceof y && (r = r[w]),
                        (this.hide().selection = e.selectChildren(i, r)),
                        this.insDirOf(o, this.selection.ends[o]),
                        this.selectionChanged(),
                        !0
                    )
                }),
                (t.clearSelection = function () {
                    return (
                        this.selection &&
                            (this.selection.clear(),
                            delete this.selection,
                            this.selectionChanged()),
                        this
                    )
                }),
                (t.deleteSelection = function () {
                    this.selection &&
                        ((this[w] = this.selection.ends[w][w]),
                        (this[x] = this.selection.ends[x][x]),
                        this.selection.remove(),
                        this.selectionChanged(),
                        delete this.selection)
                }),
                (t.replaceSelection = function () {
                    var t = this.selection
                    return (
                        t &&
                            ((this[w] = t.ends[w][w]),
                            (this[x] = t.ends[x][x]),
                            delete this.selection),
                        t
                    )
                }),
                (t.depth = function () {
                    for (var t = this, e = 0; (t = t.parent); )
                        e += t instanceof V ? 1 : 0
                    return e
                }),
                (t.isTooDeep = function (t) {
                    if (this.options.maxDepth !== m)
                        return this.depth() + (t || 0) > this.options.maxDepth
                })
        })),
        (_ = v(k, function (t, e) {
            ;(t.init = function () {
                e.init.apply(this, arguments),
                    (this.jQ = this.jQ
                        .wrapAll('<span class="mq-selection"></span>')
                        .parent())
            }),
                (t.adopt = function () {
                    return (
                        this.jQ.replaceWith((this.jQ = this.jQ.children())),
                        e.adopt.apply(this, arguments)
                    )
                }),
                (t.clear = function () {
                    return this.jQ.replaceWith(this.jQ[0].childNodes), this
                }),
                (t.join = function (t) {
                    return this.fold("", function (e, n) {
                        return e + n[t]()
                    })
                })
        })),
        (C = v(function (t) {
            ;(t.init = function (t, e, n) {
                ;(this.id = t.id),
                    (this.data = {}),
                    (this.root = t),
                    (this.container = e),
                    (this.options = n),
                    (t.controller = this),
                    (this.cursor = t.cursor = T(t, n))
            }),
                (t.handle = function (t, e) {
                    var n,
                        i = this.options.handlers
                    i &&
                        i.fns[t] &&
                        ((n = i.APIClasses[this.KIND_OF_MQ](this)),
                        e === w || e === x ? i.fns[t](e, n) : i.fns[t](n))
                })
            var e = []
            ;(this.onNotify = function (t) {
                e.push(t)
            }),
                (t.notify = function () {
                    for (var t = 0; t < e.length; t += 1)
                        e[t].apply(this.cursor, arguments)
                    return this
                })
        })),
        (D = {}),
        (S = v()),
        (E = {}),
        (A = v()),
        (L = {}),
        (l.prototype = A.p),
        (l.VERSION = "v0.10.1"),
        (l.interfaceVersion = function (t) {
            if (1 !== t)
                throw "Only interface version 1 supported. You specified: " + t
            return (
                (a = function () {
                    window.console &&
                        console.warn(
                            'You called MathQuill.interfaceVersion(1); to specify the interface version, which will fail in v1.0.0. You can fix this easily by doing this before doing anything else:\n\n    MathQuill = MathQuill.getInterface(1);\n    // now MathQuill.MathField() works like it used to\n\nSee also the "`dev` branch (2014–2015) → v0.10.0 Migration Guide" at\n  https://github.com/mathquill/mathquill/wiki/%60dev%60-branch-(2014%E2%80%932015)-%E2%86%92-v0.10.0-Migration-Guide'
                        )
                }),
                a(),
                l
            )
        }),
        (l.getInterface = c),
        (z = c.MIN = 1),
        (I = c.MAX = 2),
        (l.noConflict = function () {
            return (window.MathQuill = R), l
        }),
        (R = window.MathQuill),
        (window.MathQuill = l),
        (B = (function () {
            function e(t) {
                var e,
                    i = t.which || t.keyCode,
                    s = n[i],
                    r = []
                return (
                    t.ctrlKey && r.push("Ctrl"),
                    t.originalEvent &&
                        t.originalEvent.metaKey &&
                        r.push("Meta"),
                    t.altKey && r.push("Alt"),
                    t.shiftKey && r.push("Shift"),
                    (e = s || String.fromCharCode(i)),
                    r.length || s ? (r.push(e), r.join("-")) : e
                )
            }
            var n = {
                8: "Backspace",
                9: "Tab",
                10: "Enter",
                13: "Enter",
                16: "Shift",
                17: "Control",
                18: "Alt",
                20: "CapsLock",
                27: "Esc",
                32: "Spacebar",
                33: "PageUp",
                34: "PageDown",
                35: "End",
                36: "Home",
                37: "Left",
                38: "Up",
                39: "Right",
                40: "Down",
                45: "Insert",
                46: "Del",
                144: "NumLock",
            }
            return function (n, i) {
                function s(t) {
                    ;(y = t), clearTimeout(g), (g = setTimeout(t))
                }
                function r(e) {
                    s(function (n) {
                        ;(y = t), clearTimeout(g), e(n)
                    })
                }
                function o(e) {
                    y(),
                        (y = t),
                        clearTimeout(g),
                        x.val(e),
                        e && x[0].select && x[0].select(),
                        (b = !!e)
                }
                function a() {
                    var t = x[0]
                    return (
                        "selectionStart" in t &&
                        t.selectionStart !== t.selectionEnd
                    )
                }
                function l() {
                    i.keystroke(e(v), v)
                }
                function c(t) {
                    t.target === x[0] &&
                        ((v = t),
                        (w = null),
                        b &&
                            r(function (t) {
                                ;(t && "focusout" === t.type) ||
                                    !x[0].select ||
                                    x[0].select()
                            }),
                        l())
                }
                function h(t) {
                    t.target === x[0] && (v && w && l(), (w = t), s(f))
                }
                function u(t) {
                    t.target === x[0] && v && !w && s(f)
                }
                function f() {
                    if (!a()) {
                        var t = x.val()
                        1 === t.length
                            ? (x.val(""), i.typedText(t))
                            : t && x[0].select && x[0].select()
                    }
                }
                function p() {
                    v = w = null
                }
                function d(t) {
                    t.target === x[0] && (x.focus(), s(m))
                }
                function m() {
                    var t = x.val()
                    x.val(""), t && i.paste(t)
                }
                var g,
                    b,
                    v = null,
                    w = null,
                    x = Bt(n),
                    q = Bt(i.container || x),
                    y = t
                return (
                    q.bind(
                        "keydown keypress input keyup focusout paste",
                        function (t) {
                            y(t)
                        }
                    ),
                    (b = !1),
                    q.bind({
                        keydown: c,
                        keypress: h,
                        keyup: u,
                        focusout: p,
                        cut: function () {
                            r(function () {
                                i.cut()
                            })
                        },
                        copy: function () {
                            r(function () {
                                i.copy()
                            })
                        },
                        paste: d,
                    }),
                    { select: o }
                )
            }
        })()),
        (M = v(function (t, e, n) {
            function i(t, e) {
                throw (
                    ((t = t ? "'" + t + "'" : "EOF"),
                    "Parse Error: " + e + " at " + t)
                )
            }
            var r, o, a
            ;(t.init = function (t) {
                this._ = t
            }),
                (t.parse = function (t) {
                    function e(t, e) {
                        return e
                    }
                    return this.skip(a)._("" + t, e, i)
                }),
                (t.or = function (t) {
                    s("or is passed a parser", t instanceof n)
                    var e = this
                    return n(function (n, i, s) {
                        function r(e) {
                            return t._(n, i, s)
                        }
                        return e._(n, i, r)
                    })
                }),
                (t.then = function (t) {
                    var e = this
                    return n(function (i, r, o) {
                        function a(e, i) {
                            var a = t instanceof n ? t : t(i)
                            return (
                                s("a parser is returned", a instanceof n),
                                a._(e, r, o)
                            )
                        }
                        return e._(i, a, o)
                    })
                }),
                (t.many = function () {
                    var t = this
                    return n(function (e, n, i) {
                        function s(t, n) {
                            return (e = t), o.push(n), !0
                        }
                        function r() {
                            return !1
                        }
                        for (var o = []; t._(e, s, r); );
                        return n(e, o)
                    })
                }),
                (t.times = function (t, e) {
                    arguments.length < 2 && (e = t)
                    var i = this
                    return n(function (n, s, r) {
                        function o(t, e) {
                            return u.push(e), (n = t), !0
                        }
                        function a(t, e) {
                            return (c = e), (n = t), !1
                        }
                        function l(t, e) {
                            return !1
                        }
                        var c,
                            h,
                            u = [],
                            f = !0
                        for (h = 0; h < t; h += 1)
                            if (!(f = i._(n, o, a))) return r(n, c)
                        for (; h < e && f; h += 1) f = i._(n, o, l)
                        return s(n, u)
                    })
                }),
                (t.result = function (t) {
                    return this.then(o(t))
                }),
                (t.atMost = function (t) {
                    return this.times(0, t)
                }),
                (t.atLeast = function (t) {
                    var e = this
                    return e.times(t).then(function (t) {
                        return e.many().map(function (e) {
                            return t.concat(e)
                        })
                    })
                }),
                (t.map = function (t) {
                    return this.then(function (e) {
                        return o(t(e))
                    })
                }),
                (t.skip = function (t) {
                    return this.then(function (e) {
                        return t.result(e)
                    })
                }),
                (this.string = function (t) {
                    var e = t.length,
                        i = "expected '" + t + "'"
                    return n(function (n, s, r) {
                        var o = n.slice(0, e)
                        return o === t ? s(n.slice(e), o) : r(n, i)
                    })
                }),
                (r = this.regex =
                    function (t) {
                        s(
                            "regexp parser is anchored",
                            "^" === t.toString().charAt(1)
                        )
                        var e = "expected " + t
                        return n(function (n, i, s) {
                            var r,
                                o = t.exec(n)
                            return o
                                ? ((r = o[0]), i(n.slice(r.length), r))
                                : s(n, e)
                        })
                    }),
                (o = n.succeed =
                    function (t) {
                        return n(function (e, n) {
                            return n(e, t)
                        })
                    }),
                (n.fail = function (t) {
                    return n(function (e, n, i) {
                        return i(e, t)
                    })
                }),
                (n.letter = r(/^[a-z]/i)),
                (n.letters = r(/^[a-z]*/i)),
                (n.digit = r(/^[0-9]/)),
                (n.digits = r(/^[0-9]*/)),
                (n.whitespace = r(/^\s+/)),
                (n.optWhitespace = r(/^\s*/)),
                (n.any = n(function (t, e, n) {
                    return t
                        ? e(t.slice(1), t.charAt(0))
                        : n(t, "expected any character")
                })),
                (n.all = n(function (t, e, n) {
                    return e("", t)
                })),
                (a = n.eof =
                    n(function (t, e, n) {
                        return t ? n(t, "expected EOF") : e(t, t)
                    }))
        })),
        C.open(function (t) {
            ;(t.focusBlurEvents = function () {
                function t() {
                    clearTimeout(n),
                        r.selection && r.selection.jQ.addClass("mq-blur"),
                        e()
                }
                function e() {
                    r.hide().parent.blur(),
                        i.container.removeClass("mq-focused"),
                        q(window).unbind("blur", t)
                }
                var n,
                    i = this,
                    s = i.root,
                    r = i.cursor
                i.textarea
                    .focus(function () {
                        ;(i.blurred = !1),
                            clearTimeout(n),
                            i.container.addClass("mq-focused"),
                            r.parent || r.insAtRightEnd(s),
                            r.selection
                                ? (r.selection.jQ.removeClass("mq-blur"),
                                  i.selectionChanged())
                                : r.show()
                    })
                    .blur(function () {
                        ;(i.blurred = !0),
                            (n = setTimeout(function () {
                                s.postOrder("intentionalBlur"),
                                    r.clearSelection().endSelection(),
                                    e()
                            })),
                            q(window).bind("blur", t)
                    }),
                    (i.blurred = !0),
                    r.hide().parent.blur()
            }),
                (t.unbindFocusBlurEvents = function () {
                    this.textarea.unbind("focus blur")
                })
        }),
        C.open(function (t, e) {
            t.exportText = function () {
                return this.root.foldChildren("", function (t, e) {
                    return t + e.text()
                })
            }
        }),
        C.open(function (e) {
            ;(S.p.ignoreNextMousedown = t),
                (e.delegateMouseEvents = function () {
                    var e = this.root.jQ
                    this.container.bind("mousedown.mathquill", function (n) {
                        function i(t) {
                            o = q(t.target)
                        }
                        function s(t) {
                            h.anticursor || h.startSelection(),
                                c.seek(o, t.pageX, t.pageY).cursor.select(),
                                (o = m)
                        }
                        function r(t) {
                            ;(h.blink = u),
                                h.selection ||
                                    (c.editable ? h.show() : f.detach()),
                                a.unbind("mousemove", i),
                                q(t.target.ownerDocument)
                                    .unbind("mousemove", s)
                                    .unbind("mouseup", r)
                        }
                        var o,
                            a = q(n.target).closest(".mq-root-block"),
                            l = O.byId[a.attr(Ft) || e.attr(Ft)],
                            c = l.controller,
                            h = c.cursor,
                            u = h.blink,
                            f = c.textareaSpan,
                            p = c.textarea
                        n.preventDefault(),
                            (n.target.unselectable = !0),
                            h.options.ignoreNextMousedown(n) ||
                                ((h.options.ignoreNextMousedown = t),
                                c.blurred &&
                                    (c.editable || a.prepend(f), p.focus()),
                                (h.blink = t),
                                c
                                    .seek(q(n.target), n.pageX, n.pageY)
                                    .cursor.startSelection(),
                                a.mousemove(i),
                                q(n.target.ownerDocument)
                                    .mousemove(s)
                                    .mouseup(r))
                    })
                })
        }),
        C.open(function (t) {
            t.seek = function (t, e, n) {
                var i,
                    r,
                    o,
                    a = this.notify("select").cursor
                return (
                    t &&
                        ((i = t.attr(Ft) || t.attr(Mt)) ||
                            ((r = t.parent()), (i = r.attr(Ft) || r.attr(Mt)))),
                    (o = i ? O.byId[i] : this.root),
                    s("nodeId is the id of some Node that exists", o),
                    a.clearSelection().show(),
                    o.seek(e, a),
                    this.scrollHoriz(),
                    this
                )
            }
        }),
        C.open(function (t) {
            t.keystroke = function (t, e) {
                this.cursor.parent.keystroke(t, e, this)
            }
        }),
        O.open(function (t) {
            ;(t.keystroke = function (t, e, n) {
                var i = n.cursor
                switch (t) {
                    case "Ctrl-Shift-Backspace":
                    case "Ctrl-Backspace":
                        n.ctrlDeleteDir(w)
                        break
                    case "Shift-Backspace":
                    case "Backspace":
                        n.backspace()
                        break
                    case "Esc":
                    case "Tab":
                        return void n.escapeDir(x, t, e)
                    case "Shift-Tab":
                    case "Shift-Esc":
                        return void n.escapeDir(w, t, e)
                    case "End":
                        n.notify("move").cursor.insAtRightEnd(i.parent)
                        break
                    case "Ctrl-End":
                        n.notify("move").cursor.insAtRightEnd(n.root)
                        break
                    case "Shift-End":
                        for (; i[x]; ) n.selectRight()
                        break
                    case "Ctrl-Shift-End":
                        for (; i[x] || i.parent !== n.root; ) n.selectRight()
                        break
                    case "Home":
                        n.notify("move").cursor.insAtLeftEnd(i.parent)
                        break
                    case "Ctrl-Home":
                        n.notify("move").cursor.insAtLeftEnd(n.root)
                        break
                    case "Shift-Home":
                        for (; i[w]; ) n.selectLeft()
                        break
                    case "Ctrl-Shift-Home":
                        for (; i[w] || i.parent !== n.root; ) n.selectLeft()
                        break
                    case "Left":
                        n.moveLeft()
                        break
                    case "Shift-Left":
                        n.selectLeft()
                        break
                    case "Ctrl-Left":
                        break
                    case "Right":
                        n.moveRight()
                        break
                    case "Shift-Right":
                        n.selectRight()
                        break
                    case "Ctrl-Right":
                        break
                    case "Up":
                        n.moveUp()
                        break
                    case "Down":
                        n.moveDown()
                        break
                    case "Shift-Up":
                        if (i[w]) for (; i[w]; ) n.selectLeft()
                        else n.selectLeft()
                    case "Shift-Down":
                        if (i[x]) for (; i[x]; ) n.selectRight()
                        else n.selectRight()
                    case "Ctrl-Up":
                    case "Ctrl-Down":
                        break
                    case "Ctrl-Shift-Del":
                    case "Ctrl-Del":
                        n.ctrlDeleteDir(x)
                        break
                    case "Shift-Del":
                    case "Del":
                        n.deleteForward()
                        break
                    case "Meta-A":
                    case "Ctrl-A":
                        for (
                            n.notify("move").cursor.insAtRightEnd(n.root);
                            i[w];

                        )
                            n.selectLeft()
                        break
                    default:
                        return
                }
                e.preventDefault(), n.scrollHoriz()
            }),
                (t.moveOutOf =
                    t.moveTowards =
                    t.deleteOutOf =
                    t.deleteTowards =
                    t.unselectInto =
                    t.selectOutOf =
                    t.selectTowards =
                        function () {
                            s("overridden or never called on this node")
                        })
        }),
        C.open(function (t) {
            function e(t, e) {
                var n = t.notify("upDown").cursor,
                    i = e + "Into",
                    s = e + "OutOf"
                return (
                    n[x][i]
                        ? n.insAtLeftEnd(n[x][i])
                        : n[w][i]
                        ? n.insAtRightEnd(n[w][i])
                        : n.parent.bubble(function (t) {
                              var e = t[s]
                              if (
                                  e &&
                                  ("function" == typeof e && (e = t[s](n)),
                                  e instanceof O && n.jumpUpDown(t, e),
                                  !0 !== e)
                              )
                                  return !1
                          }),
                    t
                )
            }
            this.onNotify(function (t) {
                ;("move" !== t && "upDown" !== t) ||
                    this.show().clearSelection()
            }),
                (t.escapeDir = function (t, e, n) {
                    r(t)
                    var i = this.cursor
                    if (
                        (i.parent !== this.root && n.preventDefault(),
                        i.parent !== this.root)
                    )
                        return i.parent.moveOutOf(t, i), this.notify("move")
                }),
                (E.leftRightIntoCmdGoes = function (t) {
                    if (t && "up" !== t && "down" !== t)
                        throw (
                            '"up" or "down" required for leftRightIntoCmdGoes option, got "' +
                            t +
                            '"'
                        )
                    return t
                }),
                (t.moveDir = function (t) {
                    r(t)
                    var e = this.cursor,
                        n = e.options.leftRightIntoCmdGoes
                    return (
                        e.selection
                            ? e.insDirOf(t, e.selection.ends[t])
                            : e[t]
                            ? e[t].moveTowards(t, e, n)
                            : e.parent.moveOutOf(t, e, n),
                        this.notify("move")
                    )
                }),
                (t.moveLeft = function () {
                    return this.moveDir(w)
                }),
                (t.moveRight = function () {
                    return this.moveDir(x)
                }),
                (t.moveUp = function () {
                    return e(this, "up")
                }),
                (t.moveDown = function () {
                    return e(this, "down")
                }),
                this.onNotify(function (t) {
                    "upDown" !== t && (this.upDownCache = {})
                }),
                this.onNotify(function (t) {
                    "edit" === t && this.show().deleteSelection()
                }),
                (t.deleteDir = function (t) {
                    var e, n
                    return (
                        r(t),
                        (e = this.cursor),
                        (n = e.selection),
                        this.notify("edit"),
                        n ||
                            (e[t]
                                ? e[t].deleteTowards(t, e)
                                : e.parent.deleteOutOf(t, e)),
                        e[w].siblingDeleted &&
                            e[w].siblingDeleted(e.options, x),
                        e[x].siblingDeleted &&
                            e[x].siblingDeleted(e.options, w),
                        e.parent.bubble("reflow"),
                        this
                    )
                }),
                (t.ctrlDeleteDir = function (t) {
                    r(t)
                    var e = this.cursor
                    return !e[t] || e.selection
                        ? this.deleteDir(t)
                        : (this.notify("edit"),
                          t === w
                              ? k(e.parent.ends[w], e[w]).remove()
                              : k(e[x], e.parent.ends[x]).remove(),
                          e.insAtDirEnd(t, e.parent),
                          e[w].siblingDeleted &&
                              e[w].siblingDeleted(e.options, x),
                          e[x].siblingDeleted &&
                              e[x].siblingDeleted(e.options, w),
                          e.parent.bubble("reflow"),
                          this)
                }),
                (t.backspace = function () {
                    return this.deleteDir(w)
                }),
                (t.deleteForward = function () {
                    return this.deleteDir(x)
                }),
                this.onNotify(function (t) {
                    "select" !== t && this.endSelection()
                }),
                (t.selectDir = function (t) {
                    var e,
                        n = this.notify("select").cursor,
                        i = n.selection
                    r(t),
                        n.anticursor || n.startSelection(),
                        (e = n[t]),
                        e
                            ? i && i.ends[t] === e && n.anticursor[-t] !== e
                                ? e.unselectInto(t, n)
                                : e.selectTowards(t, n)
                            : n.parent.selectOutOf(t, n),
                        n.clearSelection(),
                        n.select() || n.show()
                }),
                (t.selectLeft = function () {
                    return this.selectDir(w)
                }),
                (t.selectRight = function () {
                    return this.selectDir(x)
                })
        }),
        C.open(function (t) {
            ;(S.p.substituteTextarea = function () {
                return q(
                    "<textarea autocapitalize=off autocomplete=off autocorrect=off spellcheck=false x-palm-disable-ste-all=true />"
                )[0]
            }),
                (t.createTextarea = function () {
                    var t,
                        e = (this.textareaSpan = q(
                            '<span class="mq-textarea"></span>'
                        )),
                        n = this.options.substituteTextarea()
                    if (!n.nodeType)
                        throw (
                            "substituteTextarea() must return a DOM element, got " +
                            n
                        )
                    ;(n = this.textarea = q(n).appendTo(e)),
                        (t = this),
                        (t.cursor.selectionChanged = function () {
                            t.selectionChanged()
                        })
                }),
                (t.selectionChanged = function () {
                    var t = this
                    J(t.container[0]),
                        t.textareaSelectionTimeout === m &&
                            (t.textareaSelectionTimeout = setTimeout(
                                function () {
                                    t.setTextareaSelection()
                                }
                            ))
                }),
                (t.setTextareaSelection = function () {
                    this.textareaSelectionTimeout = m
                    var t = ""
                    this.cursor.selection &&
                        ((t = this.cursor.selection.join("latex")),
                        this.options.statelessClipboard && (t = "$" + t + "$")),
                        this.selectFn(t)
                }),
                (t.staticMathTextareaEvents = function () {
                    function t() {
                        s.detach(), (e.blurred = !0)
                    }
                    var e = this,
                        n = (e.root, e.cursor),
                        i = e.textarea,
                        s = e.textareaSpan
                    this.container.prepend(
                        Bt('<span class="mq-selectable">').text(
                            "$" + e.exportLatex() + "$"
                        )
                    ),
                        (e.blurred = !0),
                        i
                            .bind("cut paste", !1)
                            .bind("copy", function () {
                                e.setTextareaSelection()
                            })
                            .focus(function () {
                                e.blurred = !1
                            })
                            .blur(function () {
                                n.selection && n.selection.clear(),
                                    setTimeout(t)
                            }),
                        (e.selectFn = function (t) {
                            i.val(t), t && i.select()
                        })
                }),
                (S.p.substituteKeyboardEvents = B),
                (t.editablesTextareaEvents = function () {
                    var t = this,
                        e = t.textarea,
                        n = t.textareaSpan,
                        i = this.options.substituteKeyboardEvents(e, this)
                    ;(this.selectFn = function (t) {
                        i.select(t)
                    }),
                        this.container.prepend(n),
                        this.focusBlurEvents()
                }),
                (t.unbindEditablesEvents = function () {
                    var t = this,
                        e = t.textarea,
                        n = t.textareaSpan
                    ;(this.selectFn = function (t) {
                        e.val(t), t && e.select()
                    }),
                        n.remove(),
                        this.unbindFocusBlurEvents(),
                        (t.blurred = !0),
                        e.bind("cut paste", !1)
                }),
                (t.typedText = function (t) {
                    if ("\n" === t) return this.handle("enter")
                    var e = this.notify().cursor
                    e.parent.write(e, t), this.scrollHoriz()
                }),
                (t.cut = function () {
                    var t = this,
                        e = t.cursor
                    e.selection &&
                        setTimeout(function () {
                            t.notify("edit"), e.parent.bubble("reflow")
                        })
                }),
                (t.copy = function () {
                    this.setTextareaSelection()
                }),
                (t.paste = function (t) {
                    this.options.statelessClipboard &&
                        (t =
                            "$" === t.slice(0, 1) && "$" === t.slice(-1)
                                ? t.slice(1, -1)
                                : "\\text{" + t + "}"),
                        this.writeLatex(t).cursor.show()
                })
        }),
        (F = (function () {
            function t(t) {
                var e = V()
                return t.adopt(e, 0, 0), e
            }
            function e(t) {
                var e,
                    n = t[0] || V()
                for (e = 1; e < t.length; e += 1)
                    t[e].children().adopt(n, n.ends[x], 0)
                return n
            }
            var n = M.string,
                i = M.regex,
                s = M.letter,
                r = M.any,
                o = M.optWhitespace,
                a = M.succeed,
                l = M.fail,
                c = s.map(function (t) {
                    return Ot(t)
                }),
                h = i(/^[^${}\\_^]/).map(function (t) {
                    return W(t)
                }),
                u = i(/^[^\\a-eg-zA-Z]/)
                    .or(
                        n("\\").then(
                            i(/^[a-z]+/i)
                                .or(i(/^\s+/).result(" "))
                                .or(r)
                        )
                    )
                    .then(function (t) {
                        var e = j[t]
                        return e ? e(t).parser() : l("unknown command: \\" + t)
                    }),
                f = u.or(c).or(h),
                p = n("{")
                    .then(function () {
                        return m
                    })
                    .skip(n("}")),
                d = o.then(p.or(f.map(t))),
                m = d.many().map(e).skip(o),
                g = n("[")
                    .then(
                        d
                            .then(function (t) {
                                return "]" !== t.join("latex") ? a(t) : l()
                            })
                            .many()
                            .map(e)
                            .skip(o)
                    )
                    .skip(n("]")),
                b = m
            return (b.block = d), (b.optBlock = g), b
        })()),
        C.open(function (t, e) {
            ;(t.exportLatex = function () {
                return this.root.latex().replace(/(\\[a-z]+) (?![a-z])/gi, "$1")
            }),
                (E.maxDepth = function (t) {
                    return "number" == typeof t ? t : m
                }),
                (t.writeLatex = function (t) {
                    var e = this.notify("edit").cursor
                    return e.parent.writeLatex(e, t), this
                }),
                (t.renderLatexMath = function (t) {
                    var e,
                        n = this.root,
                        i = this.cursor,
                        s = (i.options, n.jQ),
                        r = M.all,
                        o = M.eof,
                        a = F.skip(o).or(r.result(!1)).parse(t)
                    n.eachChild("postOrder", "dispose"),
                        (n.ends[w] = n.ends[x] = 0),
                        a && a.prepareInsertionAt(i)
                            ? (a.children().adopt(n, 0, 0),
                              (e = a.join("html")),
                              s.html(e),
                              n.jQize(s.children()),
                              n.finalizeInsert(i.options))
                            : s.empty(),
                        delete i.selection,
                        i.insAtRightEnd(n)
                }),
                (t.renderLatexText = function (t) {
                    var e,
                        n,
                        i,
                        s,
                        r,
                        o,
                        a,
                        l,
                        c,
                        h,
                        u = this.root,
                        f = this.cursor
                    if (
                        (u.jQ.children().slice(1).remove(),
                        u.eachChild("postOrder", "dispose"),
                        (u.ends[w] = u.ends[x] = 0),
                        delete f.selection,
                        f.show().insAtRightEnd(u),
                        (e = M.regex),
                        (n = M.string),
                        (i = M.eof),
                        (s = M.all),
                        (r = n("$")
                            .then(F)
                            .skip(n("$").or(i))
                            .map(function (t) {
                                var e,
                                    n = Z(f)
                                return (
                                    n.createBlocks(),
                                    (e = n.ends[w]),
                                    t.children().adopt(e, 0, 0),
                                    n
                                )
                            })),
                        (o = n("\\$").result("$")),
                        (a = o.or(e(/^[^$]/)).map(W)),
                        (l = r.or(a).many()),
                        (c = l.skip(i).or(s.result(!1)).parse(t)))
                    ) {
                        for (h = 0; h < c.length; h += 1)
                            c[h].adopt(u, u.ends[x], 0)
                        u.jQize().appendTo(u.jQ), u.finalizeInsert(f.options)
                    }
                })
        }),
        C.open(function (t) {
            t.scrollHoriz = function () {
                var t,
                    e,
                    n,
                    i,
                    s,
                    r = this.cursor,
                    o = r.selection,
                    a = this.root.jQ[0].getBoundingClientRect()
                if (o)
                    if (
                        ((n = o.jQ[0].getBoundingClientRect()),
                        (i = n.left - (a.left + 20)),
                        (s = n.right - (a.right - 20)),
                        o.ends[w] === r[x])
                    )
                        if (i < 0) e = i
                        else {
                            if (!(s > 0)) return
                            e = n.left - s < a.left + 20 ? i : s
                        }
                    else if (s > 0) e = s
                    else {
                        if (!(i < 0)) return
                        e = n.right - i > a.right - 20 ? s : i
                    }
                else if (
                    (t = r.jQ[0].getBoundingClientRect().left) >
                    a.right - 20
                )
                    e = t - (a.right - 20)
                else {
                    if (!(t < a.left + 20)) return
                    e = t - (a.left + 20)
                }
                this.root.jQ.stop().animate({ scrollLeft: "+=" + e }, 100)
            }
        }),
        (N = v(O, function (t, e) {
            ;(t.finalizeInsert = function (t, e) {
                var n = this
                n.postOrder("finalizeTree", t),
                    n.postOrder("contactWeld", e),
                    n.postOrder("blur"),
                    n.postOrder("reflow"),
                    n[x].siblingCreated && n[x].siblingCreated(t, w),
                    n[w].siblingCreated && n[w].siblingCreated(t, x),
                    n.bubble("reflow")
            }),
                (t.prepareInsertionAt = function (t) {
                    var e,
                        n = t.options.maxDepth
                    if (n !== m) {
                        if ((e = t.depth()) > n) return !1
                        this.removeNodesDeeperThan(n - e)
                    }
                    return !0
                }),
                (t.removeNodesDeeperThan = function (t) {
                    for (var e, n = 0, i = [[this, n]]; i.length; )
                        (e = i.shift()),
                            e[0].children().each(function (s) {
                                var r = s instanceof V ? 1 : 0
                                ;(n = e[1] + r),
                                    n <= t
                                        ? i.push([s, n])
                                        : (r ? s.children() : s).remove()
                            })
                })
        })),
        (P = v(N, function (t, e) {
            ;(t.init = function (t, n, i) {
                var s = this
                e.init.call(s),
                    s.ctrlSeq || (s.ctrlSeq = t),
                    n && (s.htmlTemplate = n),
                    i && (s.textTemplate = i)
            }),
                (t.replaces = function (t) {
                    t.disown(), (this.replacedFragment = t)
                }),
                (t.isEmpty = function () {
                    return this.foldChildren(!0, function (t, e) {
                        return t && e.isEmpty()
                    })
                }),
                (t.parser = function () {
                    var t = F.block,
                        e = this
                    return t.times(e.numBlocks()).map(function (t) {
                        e.blocks = t
                        for (var n = 0; n < t.length; n += 1)
                            t[n].adopt(e, e.ends[x], 0)
                        return e
                    })
                }),
                (t.createLeftOf = function (t) {
                    var n = this,
                        i = n.replacedFragment
                    n.createBlocks(),
                        e.createLeftOf.call(n, t),
                        i &&
                            (i.adopt(n.ends[w], 0, 0),
                            i.jQ.appendTo(n.ends[w].jQ),
                            n.placeCursor(t),
                            n.prepareInsertionAt(t)),
                        n.finalizeInsert(t.options),
                        n.placeCursor(t)
                }),
                (t.createBlocks = function () {
                    var t,
                        e,
                        n = this,
                        i = n.numBlocks(),
                        s = (n.blocks = Array(i))
                    for (t = 0; t < i; t += 1)
                        (e = s[t] = V()), e.adopt(n, n.ends[x], 0)
                }),
                (t.placeCursor = function (t) {
                    t.insAtRightEnd(
                        this.foldChildren(this.ends[w], function (t, e) {
                            return t.isEmpty() ? t : e
                        })
                    )
                }),
                (t.moveTowards = function (t, e, n) {
                    var i = n && this[n + "Into"]
                    e.insAtDirEnd(-t, i || this.ends[-t])
                }),
                (t.deleteTowards = function (t, e) {
                    this.isEmpty()
                        ? (e[t] = this.remove()[t])
                        : this.moveTowards(t, e, null)
                }),
                (t.selectTowards = function (t, e) {
                    ;(e[-t] = this), (e[t] = this[t])
                }),
                (t.selectChildren = function () {
                    return _(this, this)
                }),
                (t.unselectInto = function (t, e) {
                    e.insAtDirEnd(-t, e.anticursor.ancestors[this.id])
                }),
                (t.seek = function (t, e) {
                    function n(t) {
                        var e = {}
                        return (
                            (e[w] = t.jQ.offset().left),
                            (e[x] = e[w] + t.jQ.outerWidth()),
                            e
                        )
                    }
                    var i,
                        s = this,
                        r = n(s)
                    return t < r[w]
                        ? e.insLeftOf(s)
                        : t > r[x]
                        ? e.insRightOf(s)
                        : ((i = r[w]),
                          void s.eachChild(function (o) {
                              var a = n(o)
                              return t < a[w]
                                  ? (t - i < a[w] - t
                                        ? o[w]
                                            ? e.insAtRightEnd(o[w])
                                            : e.insLeftOf(s)
                                        : e.insAtLeftEnd(o),
                                    !1)
                                  : t > a[x]
                                  ? void (o[x]
                                        ? (i = a[x])
                                        : r[x] - t < t - a[x]
                                        ? e.insRightOf(s)
                                        : e.insAtRightEnd(o))
                                  : (o.seek(t, e), !1)
                          }))
                }),
                (t.numBlocks = function () {
                    var t = this.htmlTemplate.match(/&\d+/g)
                    return t ? t.length : 0
                }),
                (t.html = function () {
                    var t,
                        e,
                        n,
                        i = this,
                        r = i.blocks,
                        o = " mathquill-command-id=" + i.id,
                        a = i.htmlTemplate.match(/<[^<>]+>|[^<>]+/g)
                    for (
                        s(
                            "no unmatched angle brackets",
                            a.join("") === this.htmlTemplate
                        ),
                            t = 0,
                            e = a[0];
                        e;
                        t += 1, e = a[t]
                    )
                        if ("/>" === e.slice(-2))
                            a[t] = e.slice(0, -2) + o + "/>"
                        else if ("<" === e.charAt(0)) {
                            s(
                                "not an unmatched top-level close tag",
                                "/" !== e.charAt(1)
                            ),
                                (a[t] = e.slice(0, -1) + o + ">"),
                                (n = 1)
                            do {
                                ;(t += 1),
                                    (e = a[t]),
                                    s("no missing close tags", e),
                                    "</" === e.slice(0, 2)
                                        ? (n -= 1)
                                        : "<" === e.charAt(0) &&
                                          "/>" !== e.slice(-2) &&
                                          (n += 1)
                            } while (n > 0)
                        }
                    return a.join("").replace(/>&(\d+)/g, function (t, e) {
                        return (
                            " mathquill-block-id=" +
                            r[e].id +
                            ">" +
                            r[e].join("html")
                        )
                    })
                }),
                (t.latex = function () {
                    return this.foldChildren(this.ctrlSeq, function (t, e) {
                        return t + "{" + (e.latex() || " ") + "}"
                    })
                }),
                (t.textTemplate = [""]),
                (t.text = function () {
                    var t = this,
                        e = 0
                    return t.foldChildren(t.textTemplate[e], function (n, i) {
                        e += 1
                        var s = i.text()
                        return n &&
                            "(" === t.textTemplate[e] &&
                            "(" === s[0] &&
                            ")" === s.slice(-1)
                            ? n + s.slice(1, -1) + t.textTemplate[e]
                            : n + s + (t.textTemplate[e] || "")
                    })
                })
        })),
        ($ = v(P, function (e, n) {
            ;(e.init = function (t, e, i) {
                i || (i = t && t.length > 1 ? t.slice(1) : t),
                    n.init.call(this, t, e, [i])
            }),
                (e.parser = function () {
                    return M.succeed(this)
                }),
                (e.numBlocks = function () {
                    return 0
                }),
                (e.replaces = function (t) {
                    t.remove()
                }),
                (e.createBlocks = t),
                (e.moveTowards = function (t, e) {
                    e.jQ.insDirOf(t, this.jQ), (e[-t] = this), (e[t] = this[t])
                }),
                (e.deleteTowards = function (t, e) {
                    e[t] = this.remove()[t]
                }),
                (e.seek = function (t, e) {
                    t - this.jQ.offset().left < this.jQ.outerWidth() / 2
                        ? e.insLeftOf(this)
                        : e.insRightOf(this)
                }),
                (e.latex = function () {
                    return this.ctrlSeq
                }),
                (e.text = function () {
                    return this.textTemplate
                }),
                (e.placeCursor = t),
                (e.isEmpty = function () {
                    return !0
                })
        })),
        (W = v($, function (t, e) {
            t.init = function (t, n) {
                e.init.call(this, t, "<span>" + (n || t) + "</span>")
            }
        })),
        (U = v($, function (t, e) {
            t.init = function (t, n, i) {
                e.init.call(
                    this,
                    t,
                    '<span class="mq-binary-operator">' + n + "</span>",
                    i
                )
            }
        })),
        (V = v(N, function (t, e) {
            ;(t.join = function (t) {
                return this.foldChildren("", function (e, n) {
                    return e + n[t]()
                })
            }),
                (t.html = function () {
                    return this.join("html")
                }),
                (t.latex = function () {
                    return this.join("latex")
                }),
                (t.text = function () {
                    return this.ends[w] === this.ends[x] && 0 !== this.ends[w]
                        ? this.ends[w].text()
                        : this.join("text")
                }),
                (t.keystroke = function (t, n, i) {
                    return !i.options.spaceBehavesLikeTab ||
                        ("Spacebar" !== t && "Shift-Spacebar" !== t)
                        ? e.keystroke.apply(this, arguments)
                        : (n.preventDefault(),
                          void i.escapeDir(
                              "Shift-Spacebar" === t ? w : x,
                              t,
                              n
                          ))
                }),
                (t.moveOutOf = function (t, e, n) {
                    ;(n && this.parent[n + "Into"]) || !this[t]
                        ? e.insDirOf(t, this.parent)
                        : e.insAtDirEnd(-t, this[t])
                }),
                (t.selectOutOf = function (t, e) {
                    e.insDirOf(t, this.parent)
                }),
                (t.deleteOutOf = function (t, e) {
                    e.unwrapGramp()
                }),
                (t.seek = function (t, e) {
                    var n = this.ends[x]
                    if (!n || n.jQ.offset().left + n.jQ.outerWidth() < t)
                        return e.insAtRightEnd(this)
                    if (t < this.ends[w].jQ.offset().left)
                        return e.insAtLeftEnd(this)
                    for (; t < n.jQ.offset().left; ) n = n[w]
                    return n.seek(t, e)
                }),
                (t.chToCmd = function (t, e) {
                    var n
                    return t.match(/^[a-eg-zA-Z]$/)
                        ? Ot(t)
                        : /^\d$/.test(t)
                        ? qt(t)
                        : e && e.typingSlashWritesDivisionSymbol && "/" === t
                        ? j["÷"](t)
                        : e && e.typingAsteriskWritesTimesSymbol && "*" === t
                        ? j["×"](t)
                        : (n = Q[t] || j[t])
                        ? n(t)
                        : W(t)
                }),
                (t.write = function (t, e) {
                    var n = this.chToCmd(e, t.options)
                    t.selection && n.replaces(t.replaceSelection()),
                        t.isTooDeep() || n.createLeftOf(t.show())
                }),
                (t.writeLatex = function (t, e) {
                    var n,
                        i = M.all,
                        s = M.eof,
                        r = F.skip(s).or(i.result(!1)).parse(e)
                    r &&
                        !r.isEmpty() &&
                        r.prepareInsertionAt(t) &&
                        (r.children().adopt(t.parent, t[w], t[x]),
                        (n = r.jQize()),
                        n.insertBefore(t.jQ),
                        (t[w] = r.ends[x]),
                        r.finalizeInsert(t.options, t),
                        r.ends[x][x].siblingCreated &&
                            r.ends[x][x].siblingCreated(t.options, w),
                        r.ends[w][w].siblingCreated &&
                            r.ends[w][w].siblingCreated(t.options, x),
                        t.parent.bubble("reflow"))
                }),
                (t.focus = function () {
                    return (
                        this.jQ.addClass("mq-hasCursor"),
                        this.jQ.removeClass("mq-empty"),
                        this
                    )
                }),
                (t.blur = function () {
                    return (
                        this.jQ.removeClass("mq-hasCursor"),
                        this.isEmpty() && this.jQ.addClass("mq-empty"),
                        this
                    )
                })
        })),
        (S.p.mouseEvents = !0),
        (D.StaticMath = function (t) {
            return v(t.AbstractMathQuill, function (e, n) {
                ;(this.RootBlock = V),
                    (e.__mathquillify = function (t, e) {
                        return (
                            this.config(t),
                            n.__mathquillify.call(this, "mq-math-mode"),
                            this.__options.mouseEvents &&
                                (this.__controller.delegateMouseEvents(),
                                this.__controller.staticMathTextareaEvents()),
                            this
                        )
                    }),
                    (e.init = function () {
                        n.init.apply(this, arguments),
                            this.__controller.root.postOrder(
                                "registerInnerField",
                                (this.innerFields = []),
                                t.InnerMathField
                            )
                    }),
                    (e.latex = function () {
                        var e = n.latex.apply(this, arguments)
                        return (
                            arguments.length > 0 &&
                                this.__controller.root.postOrder(
                                    "registerInnerField",
                                    (this.innerFields = []),
                                    t.InnerMathField
                                ),
                            e
                        )
                    })
            })
        }),
        (H = v(V, h)),
        (D.MathField = function (e) {
            return v(e.EditableField, function (e, n) {
                ;(this.RootBlock = H),
                    (e.__mathquillify = function (e, i) {
                        return (
                            this.config(e),
                            i > 1 && (this.__controller.root.reflow = t),
                            n.__mathquillify.call(
                                this,
                                "mq-editable-field mq-math-mode"
                            ),
                            delete this.__controller.root.reflow,
                            this
                        )
                    })
            })
        }),
        (D.InnerMathField = function (t) {
            return v(t.MathField, function (t, e) {
                ;(t.makeStatic = function () {
                    ;(this.__controller.editable = !1),
                        this.__controller.root.blur(),
                        this.__controller.unbindEditablesEvents(),
                        this.__controller.container.removeClass(
                            "mq-editable-field"
                        )
                }),
                    (t.makeEditable = function () {
                        ;(this.__controller.editable = !0),
                            this.__controller.editablesTextareaEvents(),
                            this.__controller.cursor.insAtRightEnd(
                                this.__controller.root
                            ),
                            this.__controller.container.addClass(
                                "mq-editable-field"
                            )
                    })
            })
        }),
        (G = v(O, function (t, e) {
            function n(t) {
                var e, n
                if ((t.jQ[0].normalize(), (e = t.jQ[0].firstChild)))
                    return (
                        s(
                            "only node in TextBlock span is Text node",
                            3 === e.nodeType
                        ),
                        (n = K(e.data)),
                        n.jQadd(e),
                        t.children().disown(),
                        n.adopt(t, 0, 0)
                    )
            }
            ;(t.ctrlSeq = "\\text"),
                (t.replaces = function (t) {
                    t instanceof k
                        ? (this.replacedText = t.remove().jQ.text())
                        : "string" == typeof t && (this.replacedText = t)
                }),
                (t.jQadd = function (t) {
                    e.jQadd.call(this, t),
                        this.ends[w] &&
                            this.ends[w].jQadd(this.jQ[0].firstChild)
                }),
                (t.createLeftOf = function (t) {
                    var n,
                        i = this
                    if (
                        (e.createLeftOf.call(this, t),
                        t.insAtRightEnd(i),
                        i.replacedText)
                    )
                        for (n = 0; n < i.replacedText.length; n += 1)
                            i.write(t, i.replacedText.charAt(n))
                    i[x].siblingCreated && i[x].siblingCreated(t.options, w),
                        i[w].siblingCreated &&
                            i[w].siblingCreated(t.options, x),
                        i.bubble("reflow")
                }),
                (t.parser = function () {
                    var t = this,
                        e = M.string,
                        n = M.regex
                    return M.optWhitespace
                        .then(e("{"))
                        .then(n(/^[^}]*/))
                        .skip(e("}"))
                        .map(function (e) {
                            return 0 === e.length
                                ? k()
                                : (K(e).adopt(t, 0, 0), t)
                        })
                }),
                (t.textContents = function () {
                    return this.foldChildren("", function (t, e) {
                        return t + e.text
                    })
                }),
                (t.text = function () {
                    return '"' + this.textContents() + '"'
                }),
                (t.latex = function () {
                    var t = this.textContents()
                    return 0 === t.length
                        ? ""
                        : "\\text{" +
                              t
                                  .replace(/\\/g, "\\backslash ")
                                  .replace(/[{}]/g, "\\$&") +
                              "}"
                }),
                (t.html = function () {
                    return (
                        '<span class="mq-text-mode" mathquill-command-id=' +
                        this.id +
                        ">" +
                        this.textContents() +
                        "</span>"
                    )
                }),
                (t.moveTowards = function (t, e) {
                    e.insAtDirEnd(-t, this)
                }),
                (t.moveOutOf = function (t, e) {
                    e.insDirOf(t, this)
                }),
                (t.unselectInto = t.moveTowards),
                (t.selectTowards = P.prototype.selectTowards),
                (t.deleteTowards = P.prototype.deleteTowards),
                (t.selectOutOf = function (t, e) {
                    e.insDirOf(t, this)
                }),
                (t.deleteOutOf = function (t, e) {
                    this.isEmpty() && e.insRightOf(this)
                }),
                (t.write = function (t, n) {
                    var i, s
                    t.show().deleteSelection(),
                        "$" !== n
                            ? t[w]
                                ? t[w].appendText(n)
                                : K(n).createLeftOf(t)
                            : this.isEmpty()
                            ? (t.insRightOf(this),
                              W("\\$", "$").createLeftOf(t))
                            : t[x]
                            ? t[w]
                                ? ((i = G()),
                                  (s = this.ends[w]),
                                  s.disown().jQ.detach(),
                                  s.adopt(i, 0, 0),
                                  t.insLeftOf(this),
                                  e.createLeftOf.call(i, t))
                                : t.insLeftOf(this)
                            : t.insRightOf(this),
                        this.bubble("reflow")
                }),
                (t.writeLatex = function (t, e) {
                    t[w] ? t[w].appendText(e) : K(e).createLeftOf(t),
                        this.bubble("reflow")
                }),
                (t.seek = function (t, e) {
                    var i, s, r, o, a, l, c, h
                    for (
                        e.hide(),
                            i = n(this),
                            s = this.jQ.width() / this.text.length,
                            r = Math.round((t - this.jQ.offset().left) / s),
                            r <= 0
                                ? e.insAtLeftEnd(this)
                                : r >= i.text.length
                                ? e.insAtRightEnd(this)
                                : e.insLeftOf(i.splitRight(r)),
                            o = t - e.show().offset().left,
                            a = o && o < 0 ? w : x,
                            l = a;
                        e[a] && o * l > 0;

                    )
                        e[a].moveTowards(a, e),
                            (l = o),
                            (o = t - e.offset().left)
                    a * o < -a * l && e[-a].moveTowards(-a, e),
                        e.anticursor
                            ? e.anticursor.parent === this &&
                              ((c = e[w] && e[w].text.length),
                              this.anticursorPosition === c
                                  ? (e.anticursor = y.copy(e))
                                  : (this.anticursorPosition < c
                                        ? ((h = e[w].splitRight(
                                              this.anticursorPosition
                                          )),
                                          (e[w] = h))
                                        : (h = e[x].splitRight(
                                              this.anticursorPosition - c
                                          )),
                                    (e.anticursor = y(this, h[w], h))))
                            : (this.anticursorPosition =
                                  e[w] && e[w].text.length)
                }),
                (t.blur = function (t) {
                    V.prototype.blur.call(this),
                        t &&
                            ("" === this.textContents()
                                ? (this.remove(),
                                  t[w] === this
                                      ? (t[w] = this[w])
                                      : t[x] === this && (t[x] = this[x]))
                                : n(this))
                }),
                (t.focus = V.prototype.focus)
        })),
        (K = v(O, function (t, e) {
            function n(t, e) {
                return e.charAt(t === w ? 0 : -1 + e.length)
            }
            ;(t.init = function (t) {
                e.init.call(this), (this.text = t)
            }),
                (t.jQadd = function (t) {
                    ;(this.dom = t), (this.jQ = q(t))
                }),
                (t.jQize = function () {
                    return this.jQadd(document.createTextNode(this.text))
                }),
                (t.appendText = function (t) {
                    ;(this.text += t), this.dom.appendData(t)
                }),
                (t.prependText = function (t) {
                    ;(this.text = t + this.text), this.dom.insertData(0, t)
                }),
                (t.insTextAtDirEnd = function (t, e) {
                    r(e), e === x ? this.appendText(t) : this.prependText(t)
                }),
                (t.splitRight = function (t) {
                    var e = K(this.text.slice(t)).adopt(
                        this.parent,
                        this,
                        this[x]
                    )
                    return (
                        e.jQadd(this.dom.splitText(t)),
                        (this.text = this.text.slice(0, t)),
                        e
                    )
                }),
                (t.moveTowards = function (t, e) {
                    var i, s
                    return (
                        r(t),
                        (i = n(-t, this.text)),
                        (s = this[-t]),
                        s ? s.insTextAtDirEnd(i, t) : K(i).createDir(-t, e),
                        this.deleteTowards(t, e)
                    )
                }),
                (t.latex = function () {
                    return this.text
                }),
                (t.deleteTowards = function (t, e) {
                    this.text.length > 1
                        ? t === x
                            ? (this.dom.deleteData(0, 1),
                              (this.text = this.text.slice(1)))
                            : (this.dom.deleteData(-1 + this.text.length, 1),
                              (this.text = this.text.slice(0, -1)))
                        : (this.remove(), this.jQ.remove(), (e[t] = this[t]))
                }),
                (t.selectTowards = function (t, e) {
                    var i, s, o, a
                    return (
                        r(t),
                        (i = e.anticursor),
                        (s = n(-t, this.text)),
                        i[t] === this
                            ? ((o = K(s).createDir(t, e)),
                              (i[t] = o),
                              e.insDirOf(t, o))
                            : ((a = this[-t]),
                              a
                                  ? a.insTextAtDirEnd(s, t)
                                  : ((o = K(s).createDir(-t, e)),
                                    o.jQ.insDirOf(-t, e.selection.jQ)),
                              1 === this.text.length &&
                                  i[-t] === this &&
                                  (i[-t] = this[-t])),
                        this.deleteTowards(t, e)
                    )
                })
        })),
        (j.text = j.textnormal = j.textrm = j.textup = j.textmd = G),
        (j.em =
            j.italic =
            j.italics =
            j.emph =
            j.textit =
            j.textsl =
                u("\\textit", "i", 'class="mq-text-mode"')),
        (j.strong =
            j.bold =
            j.textbf =
                u("\\textbf", "b", 'class="mq-text-mode"')),
        (j.sf = j.textsf =
            u("\\textsf", "span", 'class="mq-sans-serif mq-text-mode"')),
        (j.tt = j.texttt =
            u("\\texttt", "span", 'class="mq-monospace mq-text-mode"')),
        (j.textsc = u(
            "\\textsc",
            "span",
            'style="font-variant:small-caps" class="mq-text-mode"'
        )),
        (j.uppercase = u(
            "\\uppercase",
            "span",
            'style="text-transform:uppercase" class="mq-text-mode"'
        )),
        (j.lowercase = u(
            "\\lowercase",
            "span",
            'style="text-transform:lowercase" class="mq-text-mode"'
        )),
        (Z = v(P, function (t, e) {
            ;(t.init = function (t) {
                e.init.call(this, "$"), (this.cursor = t)
            }),
                (t.htmlTemplate = '<span class="mq-math-mode">&0</span>'),
                (t.createBlocks = function () {
                    e.createBlocks.call(this),
                        (this.ends[w].cursor = this.cursor),
                        (this.ends[w].write = function (t, e) {
                            "$" !== e
                                ? V.prototype.write.call(this, t, e)
                                : this.isEmpty()
                                ? (t.insRightOf(this.parent),
                                  this.parent.deleteTowards(dir, t),
                                  W("\\$", "$").createLeftOf(t.show()))
                                : t[x]
                                ? t[w]
                                    ? V.prototype.write.call(this, t, e)
                                    : t.insLeftOf(this.parent)
                                : t.insRightOf(this.parent)
                        })
                }),
                (t.latex = function () {
                    return "$" + this.ends[w].latex() + "$"
                })
        })),
        (Y = v(H, function (t, e) {
            ;(t.keystroke = function (t) {
                if ("Spacebar" !== t && "Shift-Spacebar" !== t)
                    return e.keystroke.apply(this, arguments)
            }),
                (t.write = function (t, e) {
                    if ((t.show().deleteSelection(), "$" === e))
                        Z(t).createLeftOf(t)
                    else {
                        var n
                        "<" === e ? (n = "&lt;") : ">" === e && (n = "&gt;"),
                            W(e, n).createLeftOf(t)
                    }
                })
        })),
        (D.TextField = function (t) {
            return v(t.EditableField, function (t, e) {
                ;(this.RootBlock = Y),
                    (t.__mathquillify = function () {
                        return e.__mathquillify.call(
                            this,
                            "mq-editable-field mq-text-mode"
                        )
                    }),
                    (t.latex = function (t) {
                        return arguments.length > 0
                            ? (this.__controller.renderLatexText(t),
                              this.__controller.blurred &&
                                  this.__controller.cursor.hide().parent.blur(),
                              this)
                            : this.__controller.exportLatex()
                    })
            })
        }),
        (Q["\\"] = v(P, function (t, e) {
            ;(t.ctrlSeq = "\\"),
                (t.replaces = function (t) {
                    ;(this._replacedFragment = t.disown()),
                        (this.isEmpty = function () {
                            return !1
                        })
                }),
                (t.htmlTemplate =
                    '<span class="mq-latex-command-input mq-non-leaf">\\<span>&0</span></span>'),
                (t.textTemplate = ["\\"]),
                (t.createBlocks = function () {
                    e.createBlocks.call(this),
                        (this.ends[w].focus = function () {
                            return (
                                this.parent.jQ.addClass("mq-hasCursor"),
                                this.isEmpty() &&
                                    this.parent.jQ.removeClass("mq-empty"),
                                this
                            )
                        }),
                        (this.ends[w].blur = function () {
                            return (
                                this.parent.jQ.removeClass("mq-hasCursor"),
                                this.isEmpty() &&
                                    this.parent.jQ.addClass("mq-empty"),
                                this
                            )
                        }),
                        (this.ends[w].write = function (t, e) {
                            t.show().deleteSelection(),
                                e.match(/[a-z]/i)
                                    ? W(e).createLeftOf(t)
                                    : (this.parent.renderCommand(t),
                                      ("\\" === e && this.isEmpty()) ||
                                          t.parent.write(t, e))
                        }),
                        (this.ends[w].keystroke = function (t, n, i) {
                            return "Tab" === t ||
                                "Enter" === t ||
                                "Spacebar" === t
                                ? (this.parent.renderCommand(i.cursor),
                                  void n.preventDefault())
                                : e.keystroke.apply(this, arguments)
                        })
                }),
                (t.createLeftOf = function (t) {
                    if (
                        (e.createLeftOf.call(this, t), this._replacedFragment)
                    ) {
                        var n = this.jQ[0]
                        this.jQ = this._replacedFragment.jQ
                            .addClass("mq-blur")
                            .bind("mousedown mousemove", function (t) {
                                return q((t.target = n)).trigger(t), !1
                            })
                            .insertBefore(this.jQ)
                            .add(this.jQ)
                    }
                }),
                (t.latex = function () {
                    return "\\" + this.ends[w].latex() + " "
                }),
                (t.renderCommand = function (t) {
                    var e, n
                    ;(this.jQ = this.jQ.last()),
                        this.remove(),
                        this[x]
                            ? t.insLeftOf(this[x])
                            : t.insAtRightEnd(this.parent),
                        (e = this.ends[w].latex()),
                        e || (e = " "),
                        (n = j[e] || bt[e]),
                        n
                            ? ((n = n(e)),
                              this._replacedFragment &&
                                  n.replaces(this._replacedFragment),
                              n.createLeftOf(t))
                            : ((n = G()),
                              n.replaces(e),
                              n.createLeftOf(t),
                              t.insRightOf(n),
                              this._replacedFragment &&
                                  this._replacedFragment.remove())
                })
        })),
        (J = t),
        (tt = document.createElement("div")),
        (et = tt.style),
        (nt = {
            transform: 1,
            WebkitTransform: 1,
            MozTransform: 1,
            OTransform: 1,
            msTransform: 1,
        })
    for (st in nt)
        if (st in et) {
            it = st
            break
        }
    it
        ? (X = function (t, e, n) {
              t.css(it, "scale(" + e + "," + n + ")")
          })
        : "filter" in et
        ? ((J = function (t) {
              t.className = t.className
          }),
          (X = function (t, e, n) {
              function i() {
                  t.css("marginRight", ((s.width() - 1) * (e - 1)) / e + "px")
              }
              var s, r
              ;(e /= 1 + (n - 1) / 2),
                  t.css("fontSize", n + "em"),
                  t.hasClass("mq-matrixed-container") ||
                      t
                          .addClass("mq-matrixed-container")
                          .wrapInner('<span class="mq-matrixed"></span>'),
                  (s = t
                      .children()
                      .css(
                          "filter",
                          "progid:DXImageTransform.Microsoft.Matrix(M11=" +
                              e +
                              ",SizingMethod='auto expand')"
                      )),
                  i(),
                  (r = setInterval(i)),
                  q(window).load(function () {
                      clearTimeout(r), i()
                  })
          }))
        : (X = function (t, e, n) {
              t.css("fontSize", n + "em")
          }),
        (rt = v(P, function (t, e) {
            t.init = function (t, n, i) {
                e.init.call(this, t, "<" + n + " " + i + ">&0</" + n + ">")
            }
        })),
        (j.mathrm = i(rt, "\\mathrm", "span", 'class="mq-roman mq-font"')),
        (j.mathit = i(rt, "\\mathit", "i", 'class="mq-font"')),
        (j.mathbf = i(rt, "\\mathbf", "b", 'class="mq-font"')),
        (j.mathsf = i(rt, "\\mathsf", "span", 'class="mq-sans-serif mq-font"')),
        (j.mathtt = i(rt, "\\mathtt", "span", 'class="mq-monospace mq-font"')),
        (j.underline = i(
            rt,
            "\\underline",
            "span",
            'class="mq-non-leaf mq-underline"'
        )),
        (j.overline = j.bar =
            i(rt, "\\overline", "span", 'class="mq-non-leaf mq-overline"')),
        (j.overrightarrow = i(
            rt,
            "\\overrightarrow",
            "span",
            'class="mq-non-leaf mq-overarrow mq-arrow-right"'
        )),
        (j.overleftarrow = i(
            rt,
            "\\overleftarrow",
            "span",
            'class="mq-non-leaf mq-overarrow mq-arrow-left"'
        )),
        (j.overleftrightarrow = i(
            rt,
            "\\overleftrightarrow",
            "span",
            'class="mq-non-leaf mq-overarrow mq-arrow-both"'
        )),
        (j.overarc = i(
            rt,
            "\\overarc",
            "span",
            'class="mq-non-leaf mq-overarc"'
        )),
        (j.dot = v(P, function (t, e) {
            t.init = function () {
                e.init.call(
                    this,
                    "\\dot",
                    '<span class="mq-non-leaf"><span class="mq-dot-recurring-inner"><span class="mq-dot-recurring">&#x2d9;</span><span class="mq-empty-box">&0</span></span></span>'
                )
            }
        })),
        (j.textcolor = v(P, function (t, e) {
            ;(t.setColor = function (t) {
                ;(this.color = t),
                    (this.htmlTemplate =
                        '<span class="mq-textcolor" style="color:' +
                        t +
                        '">&0</span>')
            }),
                (t.latex = function () {
                    return (
                        "\\textcolor{" +
                        this.color +
                        "}{" +
                        this.blocks[0].latex() +
                        "}"
                    )
                }),
                (t.parser = function () {
                    var t = this,
                        n = M.optWhitespace,
                        i = M.string,
                        s = M.regex
                    return n
                        .then(i("{"))
                        .then(s(/^[#\w\s.,()%-]*/))
                        .skip(i("}"))
                        .then(function (n) {
                            return t.setColor(n), e.parser.call(t)
                        })
                }),
                (t.isStyleBlock = function () {
                    return !0
                })
        })),
        (j.class = v(P, function (t, e) {
            ;(t.parser = function () {
                var t = this,
                    n = M.string,
                    i = M.regex
                return M.optWhitespace
                    .then(n("{"))
                    .then(i(/^[-\w\s\\\xA0-\xFF]*/))
                    .skip(n("}"))
                    .then(function (n) {
                        return (
                            (t.cls = n || ""),
                            (t.htmlTemplate =
                                '<span class="mq-class ' + n + '">&0</span>'),
                            e.parser.call(t)
                        )
                    })
            }),
                (t.latex = function () {
                    return (
                        "\\class{" +
                        this.cls +
                        "}{" +
                        this.blocks[0].latex() +
                        "}"
                    )
                }),
                (t.isStyleBlock = function () {
                    return !0
                })
        })),
        (ot = v(P, function (t, e) {
            ;(t.ctrlSeq = "_{...}^{...}"),
                (t.createLeftOf = function (t) {
                    if (
                        this.replacedFragment ||
                        t[w] ||
                        !t.options.supSubsRequireOperand
                    )
                        return e.createLeftOf.apply(this, arguments)
                }),
                (t.contactWeld = function (t) {
                    var e, n, i, s, r, o
                    for (e = w; e; e = e === w && x)
                        if (this[e] instanceof ot) {
                            for (n = "sub"; n; n = "sub" === n && "sup")
                                (i = this[n]),
                                    (s = this[e][n]),
                                    i &&
                                        (s
                                            ? i.isEmpty()
                                                ? (o = y(s, 0, s.ends[w]))
                                                : (i.jQ
                                                      .children()
                                                      .insAtDirEnd(-e, s.jQ),
                                                  (r = i.children().disown()),
                                                  (o = y(
                                                      s,
                                                      r.ends[x],
                                                      s.ends[w]
                                                  )),
                                                  e === w
                                                      ? r.adopt(s, s.ends[x], 0)
                                                      : r.adopt(
                                                            s,
                                                            0,
                                                            s.ends[w]
                                                        ))
                                            : this[e].addBlock(i.disown()),
                                        (this.placeCursor = (function (t, n) {
                                            return function (i) {
                                                i.insAtDirEnd(-e, t || n)
                                            }
                                        })(s, i)))
                            this.remove(),
                                t &&
                                    t[w] === this &&
                                    (e === x && o
                                        ? o[w]
                                            ? t.insRightOf(o[w])
                                            : t.insAtLeftEnd(o.parent)
                                        : t.insRightOf(this[e]))
                            break
                        }
                }),
                (S.p.charsThatBreakOutOfSupSub = ""),
                (t.finalizeTree = function () {
                    this.ends[w].write = function (t, e) {
                        if (
                            t.options.autoSubscriptNumerals &&
                            this === this.parent.sub
                        ) {
                            if ("_" === e) return
                            var n = this.chToCmd(e, t.options)
                            return (
                                n instanceof $
                                    ? t.deleteSelection()
                                    : t
                                          .clearSelection()
                                          .insRightOf(this.parent),
                                n.createLeftOf(t.show())
                            )
                        }
                        t[w] &&
                            !t[x] &&
                            !t.selection &&
                            t.options.charsThatBreakOutOfSupSub.indexOf(e) >
                                -1 &&
                            t.insRightOf(this.parent),
                            V.p.write.apply(this, arguments)
                    }
                }),
                (t.moveTowards = function (t, n, i) {
                    n.options.autoSubscriptNumerals && !this.sup
                        ? n.insDirOf(t, this)
                        : e.moveTowards.apply(this, arguments)
                }),
                (t.deleteTowards = function (t, n) {
                    if (n.options.autoSubscriptNumerals && this.sub) {
                        var i = this.sub.ends[-t]
                        i instanceof $
                            ? i.remove()
                            : i &&
                              i.deleteTowards(t, n.insAtDirEnd(-t, this.sub)),
                            this.sub.isEmpty() &&
                                (this.sub.deleteOutOf(
                                    w,
                                    n.insAtLeftEnd(this.sub)
                                ),
                                this.sup && n.insDirOf(-t, this))
                    } else e.deleteTowards.apply(this, arguments)
                }),
                (t.latex = function () {
                    function t(t, e) {
                        var n = e && e.latex()
                        return e
                            ? t + (1 === n.length ? n : "{" + (n || " ") + "}")
                            : ""
                    }
                    return t("_", this.sub) + t("^", this.sup)
                }),
                (t.text = function () {
                    function t(t, e) {
                        var n = e && e.text()
                        return e
                            ? t + (1 === n.length ? n : "(" + (n || " ") + ")")
                            : ""
                    }
                    return t("_", this.sub) + t("^", this.sup)
                }),
                (t.addBlock = function (t) {
                    "sub" === this.supsub
                        ? ((this.sup = this.upInto = this.sub.upOutOf = t),
                          (t.adopt(this, this.sub, 0).downOutOf = this.sub),
                          (t.jQ = q('<span class="mq-sup"/>')
                              .append(t.jQ.children())
                              .attr(Ft, t.id)
                              .prependTo(this.jQ)))
                        : ((this.sub = this.downInto = this.sup.downOutOf = t),
                          (t.adopt(this, 0, this.sup).upOutOf = this.sup),
                          (t.jQ = q('<span class="mq-sub"></span>')
                              .append(t.jQ.children())
                              .attr(Ft, t.id)
                              .appendTo(this.jQ.removeClass("mq-sup-only"))),
                          this.jQ.append(
                              '<span style="display:inline-block;width:0">&#8203;</span>'
                          ))
                    for (var e = 0; e < 2; e += 1)
                        !(function (t, e, n, i) {
                            t[e].deleteOutOf = function (s, r) {
                                if (
                                    (r.insDirOf(this[s] ? -s : s, this.parent),
                                    !this.isEmpty())
                                ) {
                                    var o = this.ends[s]
                                    this.children()
                                        .disown()
                                        .withDirAdopt(s, r.parent, r[s], r[-s])
                                        .jQ.insDirOf(-s, r.jQ),
                                        (r[-s] = o)
                                }
                                ;(t.supsub = n),
                                    delete t[e],
                                    delete t[i + "Into"],
                                    (t[n][i + "OutOf"] = f),
                                    delete t[n].deleteOutOf,
                                    "sub" === e &&
                                        q(
                                            t.jQ.addClass("mq-sup-only")[0]
                                                .lastChild
                                        ).remove(),
                                    this.remove()
                            }
                        })(
                            this,
                            "sub sup".split(" ")[e],
                            "sup sub".split(" ")[e],
                            "down up".split(" ")[e]
                        )
                }),
                (t.reflow = function () {
                    var t,
                        e,
                        n,
                        i,
                        s,
                        r = this.jQ,
                        o = r.prev()
                    o.length &&
                        ((t = r.children(".mq-sup")),
                        t.length &&
                            ((e = parseInt(t.css("font-size"))),
                            (n = t.offset().top + t.height()),
                            (i = n - o.offset().top - 0.7 * e),
                            (s = parseInt(t.css("margin-bottom"))),
                            t.css("margin-bottom", s + i)))
                })
        })),
        (j.subscript = j._ =
            v(ot, function (t, e) {
                ;(t.supsub = "sub"),
                    (t.htmlTemplate =
                        '<span class="mq-supsub mq-non-leaf"><span class="mq-sub">&0</span><span style="display:inline-block;width:0">&#8203;</span></span>'),
                    (t.textTemplate = ["_"]),
                    (t.finalizeTree = function () {
                        ;(this.downInto = this.sub = this.ends[w]),
                            (this.sub.upOutOf = f),
                            e.finalizeTree.call(this)
                    })
            })),
        (j.superscript =
            j.supscript =
            j["^"] =
                v(ot, function (t, e) {
                    ;(t.supsub = "sup"),
                        (t.htmlTemplate =
                            '<span class="mq-supsub mq-non-leaf mq-sup-only"><span class="mq-sup">&0</span></span>'),
                        (t.textTemplate = ["^(", ")"]),
                        (t.finalizeTree = function () {
                            ;(this.upInto = this.sup = this.ends[x]),
                                (this.sup.downOutOf = f),
                                e.finalizeTree.call(this)
                        })
                })),
        (at = v(P, function (t, e) {
            ;(t.init = function (t, e) {
                var n =
                    '<span class="mq-large-operator mq-non-leaf"><span class="mq-to"><span>&1</span></span><big>' +
                    e +
                    '</big><span class="mq-from"><span>&0</span></span></span>'
                $.prototype.init.call(this, t, n)
            }),
                (t.createLeftOf = function (t) {
                    e.createLeftOf.apply(this, arguments),
                        t.options.sumStartsWithNEquals &&
                            (Ot("n").createLeftOf(t), zt().createLeftOf(t))
                }),
                (t.latex = function () {
                    function t(t) {
                        return 1 === t.length ? t : "{" + (t || " ") + "}"
                    }
                    return (
                        this.ctrlSeq +
                        "_" +
                        t(this.ends[w].latex()) +
                        "^" +
                        t(this.ends[x].latex())
                    )
                }),
                (t.parser = function () {
                    var t,
                        e = M.string,
                        n = M.optWhitespace,
                        i = M.succeed,
                        s = F.block,
                        r = this,
                        o = (r.blocks = [V(), V()])
                    for (t = 0; t < o.length; t += 1)
                        o[t].adopt(r, r.ends[x], 0)
                    return n
                        .then(e("_").or(e("^")))
                        .then(function (t) {
                            var e = o["_" === t ? 0 : 1]
                            return s.then(function (t) {
                                return t.children().adopt(e, e.ends[x], 0), i(r)
                            })
                        })
                        .many()
                        .result(r)
                }),
                (t.finalizeTree = function () {
                    ;(this.downInto = this.ends[w]),
                        (this.upInto = this.ends[x]),
                        (this.ends[w].upOutOf = this.ends[x]),
                        (this.ends[x].downOutOf = this.ends[w])
                })
        })),
        (j["∑"] = j.sum = j.summation = i(at, "\\sum ", "&sum;")),
        (j["∏"] = j.prod = j.product = i(at, "\\prod ", "&prod;")),
        (j.coprod = j.coproduct = i(at, "\\coprod ", "&#8720;")),
        (j["∫"] =
            j.int =
            j.integral =
                v(at, function (t, e) {
                    ;(t.init = function () {
                        $.prototype.init.call(
                            this,
                            "\\int ",
                            '<span class="mq-int mq-non-leaf"><big>&int;</big><span class="mq-supsub mq-non-leaf"><span class="mq-sup"><span class="mq-sup-inner">&1</span></span><span class="mq-sub">&0</span><span style="display:inline-block;width:0">&#8203</span></span></span>'
                        )
                    }),
                        (t.createLeftOf = P.p.createLeftOf)
                })),
        (lt =
            j.frac =
            j.dfrac =
            j.cfrac =
            j.fraction =
                v(P, function (t, e) {
                    ;(t.ctrlSeq = "\\frac"),
                        (t.htmlTemplate =
                            '<span class="mq-fraction mq-non-leaf"><span class="mq-numerator">&0</span><span class="mq-denominator">&1</span><span style="display:inline-block;width:0">&#8203;</span></span>'),
                        (t.textTemplate = ["(", ")/(", ")"]),
                        (t.finalizeTree = function () {
                            ;(this.upInto = this.ends[x].upOutOf =
                                this.ends[w]),
                                (this.downInto = this.ends[w].downOutOf =
                                    this.ends[x])
                        })
                })),
        (ct =
            j.over =
            Q["/"] =
                v(lt, function (e, n) {
                    e.createLeftOf = function (e) {
                        if (!this.replacedFragment) {
                            for (
                                var i = e[w];
                                i &&
                                !(
                                    i instanceof U ||
                                    i instanceof (j.text || t) ||
                                    i instanceof at ||
                                    "\\ " === i.ctrlSeq ||
                                    /^[,;:]$/.test(i.ctrlSeq)
                                );

                            )
                                i = i[w]
                            i instanceof at &&
                                i[x] instanceof ot &&
                                ((i = i[x]),
                                i[x] instanceof ot &&
                                    i[x].ctrlSeq != i.ctrlSeq &&
                                    (i = i[x])),
                                i === e[w] ||
                                    e.isTooDeep(1) ||
                                    (this.replaces(
                                        k(i[x] || e.parent.ends[w], e[w])
                                    ),
                                    (e[w] = i))
                        }
                        n.createLeftOf.call(this, e)
                    }
                })),
        (ht =
            j.sqrt =
            j["√"] =
                v(P, function (t, e) {
                    ;(t.ctrlSeq = "\\sqrt"),
                        (t.htmlTemplate =
                            '<span class="mq-non-leaf"><span class="mq-scaled mq-sqrt-prefix">&radic;</span><span class="mq-non-leaf mq-sqrt-stem">&0</span></span>'),
                        (t.textTemplate = ["sqrt(", ")"]),
                        (t.parser = function () {
                            return F.optBlock
                                .then(function (t) {
                                    return F.block.map(function (e) {
                                        var n = ut()
                                        return (
                                            (n.blocks = [t, e]),
                                            t.adopt(n, 0, 0),
                                            e.adopt(n, t, 0),
                                            n
                                        )
                                    })
                                })
                                .or(e.parser.call(this))
                        }),
                        (t.reflow = function () {
                            var t = this.ends[x].jQ
                            X(
                                t.prev(),
                                1,
                                t.innerHeight() /
                                    +t.css("fontSize").slice(0, -2) -
                                    0.1
                            )
                        })
                })),
        (j.hat = v(P, function (t, e) {
            ;(t.ctrlSeq = "\\hat"),
                (t.htmlTemplate =
                    '<span class="mq-non-leaf"><span class="mq-hat-prefix">^</span><span class="mq-hat-stem">&0</span></span>'),
                (t.textTemplate = ["hat(", ")"])
        })),
        (ut = j.nthroot =
            v(ht, function (t, e) {
                ;(t.htmlTemplate =
                    '<sup class="mq-nthroot mq-non-leaf">&0</sup><span class="mq-scaled"><span class="mq-sqrt-prefix mq-scaled">&radic;</span><span class="mq-sqrt-stem mq-non-leaf">&1</span></span>'),
                    (t.textTemplate = ["sqrt[", "](", ")"]),
                    (t.latex = function () {
                        return (
                            "\\sqrt[" +
                            this.ends[w].latex() +
                            "]{" +
                            this.ends[x].latex() +
                            "}"
                        )
                    })
            })),
        (ft = v(P, function (t, e) {
            t.init = function (t, n, i) {
                var s =
                    '<span class="mq-non-leaf"><span class="mq-diacritic-above">' +
                    n +
                    '</span><span class="mq-diacritic-stem">&0</span></span>'
                e.init.call(this, t, s, i)
            }
        })),
        (j.vec = i(ft, "\\vec", "&rarr;", ["vec(", ")"])),
        (j.tilde = i(ft, "\\tilde", "~", ["tilde(", ")"])),
        (pt = v(v(P, p), function (e, n) {
            ;(e.init = function (t, e, i, s, r) {
                n.init.call(this, "\\left" + s, m, [e, i]),
                    (this.side = t),
                    (this.sides = {}),
                    (this.sides[w] = { ch: e, ctrlSeq: s }),
                    (this.sides[x] = { ch: i, ctrlSeq: r })
            }),
                (e.numBlocks = function () {
                    return 1
                }),
                (e.html = function () {
                    return (
                        (this.htmlTemplate =
                            '<span class="mq-non-leaf"><span class="mq-scaled mq-paren' +
                            (this.side === x ? " mq-ghost" : "") +
                            '">' +
                            this.sides[w].ch +
                            '</span><span class="mq-non-leaf">&0</span><span class="mq-scaled mq-paren' +
                            (this.side === w ? " mq-ghost" : "") +
                            '">' +
                            this.sides[x].ch +
                            "</span></span>"),
                        n.html.call(this)
                    )
                }),
                (e.latex = function () {
                    return (
                        "\\left" +
                        this.sides[w].ctrlSeq +
                        this.ends[w].latex() +
                        "\\right" +
                        this.sides[x].ctrlSeq
                    )
                }),
                (e.matchBrack = function (t, e, n) {
                    return (
                        n instanceof pt &&
                        n.side &&
                        n.side !== -e &&
                        (!t.restrictMismatchedBrackets ||
                            dt[this.sides[this.side].ch] ===
                                n.sides[n.side].ch ||
                            { "(": "]", "[": ")" }[this.sides[w].ch] ===
                                n.sides[x].ch) &&
                        n
                    )
                }),
                (e.closeOpposing = function (t) {
                    ;(t.side = 0),
                        (t.sides[this.side] = this.sides[this.side]),
                        t.delimjQs
                            .eq(this.side === w ? 0 : 1)
                            .removeClass("mq-ghost")
                            .html(this.sides[this.side].ch)
                }),
                (e.createLeftOf = function (t) {
                    var e, i, s
                    this.replacedFragment ||
                        ((e = t.options),
                        (i =
                            "|" === this.sides[w].ch
                                ? this.matchBrack(e, x, t[x]) ||
                                  this.matchBrack(e, w, t[w]) ||
                                  this.matchBrack(e, 0, t.parent.parent)
                                : this.matchBrack(
                                      e,
                                      -this.side,
                                      t[-this.side]
                                  ) ||
                                  this.matchBrack(
                                      e,
                                      -this.side,
                                      t.parent.parent
                                  ))),
                        i
                            ? ((s = this.side = -i.side),
                              this.closeOpposing(i),
                              i === t.parent.parent &&
                                  t[s] &&
                                  k(t[s], t.parent.ends[s], -s)
                                      .disown()
                                      .withDirAdopt(-s, i.parent, i, i[s])
                                      .jQ.insDirOf(s, i.jQ),
                              i.bubble("reflow"))
                            : ((i = this),
                              (s = i.side),
                              i.replacedFragment
                                  ? (i.side = 0)
                                  : t[-s] &&
                                    (i.replaces(k(t[-s], t.parent.ends[-s], s)),
                                    (t[-s] = 0)),
                              n.createLeftOf.call(i, t)),
                        s === w ? t.insAtLeftEnd(i.ends[w]) : t.insRightOf(i)
                }),
                (e.placeCursor = t),
                (e.unwrap = function () {
                    this.ends[w]
                        .children()
                        .disown()
                        .adopt(this.parent, this, this[x])
                        .jQ.insertAfter(this.jQ),
                        this.remove()
                }),
                (e.deleteSide = function (t, e, n) {
                    var i,
                        s,
                        r,
                        o = this.parent,
                        a = this[t],
                        l = o.ends[t]
                    if (t === this.side)
                        return (
                            this.unwrap(),
                            void (a ? n.insDirOf(-t, a) : n.insAtDirEnd(t, o))
                        )
                    if (
                        ((i = n.options),
                        (s = !this.side),
                        (this.side = -t),
                        this.matchBrack(i, t, this.ends[w].ends[this.side]))
                    )
                        this.closeOpposing(this.ends[w].ends[this.side]),
                            (r = this.ends[w].ends[t]),
                            this.unwrap(),
                            r.siblingCreated && r.siblingCreated(n.options, t),
                            a ? n.insDirOf(-t, a) : n.insAtDirEnd(t, o)
                    else {
                        if (this.matchBrack(i, t, this.parent.parent))
                            this.parent.parent.closeOpposing(this),
                                this.parent.parent.unwrap()
                        else {
                            if (e && s)
                                return (
                                    this.unwrap(),
                                    void (a
                                        ? n.insDirOf(-t, a)
                                        : n.insAtDirEnd(t, o))
                                )
                            ;(this.sides[t] = {
                                ch: dt[this.sides[this.side].ch],
                                ctrlSeq: dt[this.sides[this.side].ctrlSeq],
                            }),
                                this.delimjQs
                                    .removeClass("mq-ghost")
                                    .eq(t === w ? 0 : 1)
                                    .addClass("mq-ghost")
                                    .html(this.sides[t].ch)
                        }
                        a
                            ? ((r = this.ends[w].ends[t]),
                              k(a, l, -t)
                                  .disown()
                                  .withDirAdopt(-t, this.ends[w], r, 0)
                                  .jQ.insAtDirEnd(
                                      t,
                                      this.ends[w].jQ.removeClass("mq-empty")
                                  ),
                              r.siblingCreated &&
                                  r.siblingCreated(n.options, t),
                              n.insDirOf(-t, a))
                            : e
                            ? n.insDirOf(t, this)
                            : n.insAtDirEnd(t, this.ends[w])
                    }
                }),
                (e.deleteTowards = function (t, e) {
                    this.deleteSide(-t, !1, e)
                }),
                (e.finalizeTree = function () {
                    ;(this.ends[w].deleteOutOf = function (t, e) {
                        this.parent.deleteSide(t, !0, e)
                    }),
                        (this.finalizeTree = this.intentionalBlur =
                            function () {
                                this.delimjQs
                                    .eq(this.side === w ? 1 : 0)
                                    .removeClass("mq-ghost"),
                                    (this.side = 0)
                            })
                }),
                (e.siblingCreated = function (t, e) {
                    e === -this.side && this.finalizeTree()
                })
        })),
        (dt = {
            "(": ")",
            ")": "(",
            "[": "]",
            "]": "[",
            "{": "}",
            "}": "{",
            "\\{": "\\}",
            "\\}": "\\{",
            "&lang;": "&rang;",
            "&rang;": "&lang;",
            "\\langle ": "\\rangle ",
            "\\rangle ": "\\langle ",
            "|": "|",
            "\\lVert ": "\\rVert ",
            "\\rVert ": "\\lVert ",
        }),
        d("("),
        d("["),
        d("{", "\\{"),
        (j.langle = i(pt, w, "&lang;", "&rang;", "\\langle ", "\\rangle ")),
        (j.rangle = i(pt, x, "&lang;", "&rang;", "\\langle ", "\\rangle ")),
        (Q["|"] = i(pt, w, "|", "|", "|", "|")),
        (j.lVert = i(pt, w, "&#8741;", "&#8741;", "\\lVert ", "\\rVert ")),
        (j.rVert = i(pt, x, "&#8741;", "&#8741;", "\\lVert ", "\\rVert ")),
        (j.left = v(P, function (t) {
            t.parser = function () {
                var t = M.regex,
                    e = M.string,
                    n = (M.succeed, M.optWhitespace)
                return n
                    .then(
                        t(
                            /^(?:[([|]|\\\{|\\langle(?![a-zA-Z])|\\lVert(?![a-zA-Z]))/
                        )
                    )
                    .then(function (i) {
                        var s = "\\" === i.charAt(0) ? i.slice(1) : i
                        return (
                            "\\langle" == i && ((s = "&lang;"), (i += " ")),
                            "\\lVert" == i && ((s = "&#8741;"), (i += " ")),
                            F.then(function (r) {
                                return e("\\right")
                                    .skip(n)
                                    .then(
                                        t(
                                            /^(?:[\])|]|\\\}|\\rangle(?![a-zA-Z])|\\rVert(?![a-zA-Z]))/
                                        )
                                    )
                                    .map(function (t) {
                                        var e,
                                            n =
                                                "\\" === t.charAt(0)
                                                    ? t.slice(1)
                                                    : t
                                        return (
                                            "\\rangle" == t &&
                                                ((n = "&rang;"), (t += " ")),
                                            "\\rVert" == t &&
                                                ((n = "&#8741;"), (t += " ")),
                                            (e = pt(0, s, n, i, t)),
                                            (e.blocks = [r]),
                                            r.adopt(e, 0, 0),
                                            e
                                        )
                                    })
                            })
                        )
                    })
            }
        })),
        (j.right = v(P, function (t) {
            t.parser = function () {
                return M.fail("unmatched \\right")
            }
        })),
        (mt =
            j.binom =
            j.binomial =
                v(v(P, p), function (t, e) {
                    ;(t.ctrlSeq = "\\binom"),
                        (t.htmlTemplate =
                            '<span class="mq-non-leaf"><span class="mq-paren mq-scaled">(</span><span class="mq-non-leaf"><span class="mq-array mq-non-leaf"><span>&0</span><span>&1</span></span></span><span class="mq-paren mq-scaled">)</span></span>'),
                        (t.textTemplate = ["choose(", ",", ")"])
                })),
        (j.choose = v(mt, function (t) {
            t.createLeftOf = ct.prototype.createLeftOf
        })),
        (j.editable = j.MathQuillMathField =
            v(P, function (t, e) {
                ;(t.ctrlSeq = "\\MathQuillMathField"),
                    (t.htmlTemplate =
                        '<span class="mq-editable-field"><span class="mq-root-block">&0</span></span>'),
                    (t.parser = function () {
                        var t = this,
                            n = M.string,
                            i = M.regex,
                            s = M.succeed
                        return n("[")
                            .then(i(/^[a-z][a-z0-9]*/i))
                            .skip(n("]"))
                            .map(function (e) {
                                t.name = e
                            })
                            .or(s())
                            .then(e.parser.call(t))
                    }),
                    (t.finalizeTree = function (t) {
                        var e = C(this.ends[w], this.jQ, t)
                        ;(e.KIND_OF_MQ = "MathField"),
                            (e.editable = !0),
                            e.createTextarea(),
                            e.editablesTextareaEvents(),
                            e.cursor.insAtRightEnd(e.root),
                            h(e.root)
                    }),
                    (t.registerInnerField = function (t, e) {
                        t.push((t[this.name] = e(this.ends[w].controller)))
                    }),
                    (t.latex = function () {
                        return this.ends[w].latex()
                    }),
                    (t.text = function () {
                        return this.ends[w].text()
                    })
            })),
        (gt = j.embed =
            v($, function (t, e) {
                ;(t.setOptions = function (t) {
                    function e() {
                        return ""
                    }
                    return (
                        (this.text = t.text || e),
                        (this.htmlTemplate = t.htmlString || ""),
                        (this.latex = t.latex || e),
                        this
                    )
                }),
                    (t.parser = function () {
                        var t = this,
                            e = M.string,
                            n = M.regex,
                            i = M.succeed
                        return e("{")
                            .then(n(/^[a-z][a-z0-9]*/i))
                            .skip(e("}"))
                            .then(function (s) {
                                return e("[")
                                    .then(n(/^[-\w\s]*/))
                                    .skip(e("]"))
                                    .or(i())
                                    .map(function (e) {
                                        return t.setOptions(L[s](e))
                                    })
                            })
                    })
            })),
        (bt = {}),
        (j.begin = v(P, function (t, e) {
            t.parser = function () {
                var t = M.string,
                    e = M.regex
                return t("{")
                    .then(e(/^[a-z]+/i))
                    .skip(t("}"))
                    .then(function (e) {
                        return (
                            bt[e]
                                ? bt[e]().parser()
                                : M.fail("unknown environment type: " + e)
                        ).skip(t("\\end{" + e + "}"))
                    })
            }
        })),
        (vt = v(P, function (t, e) {
            ;(t.template = [
                ["\\begin{", "}"],
                ["\\end{", "}"],
            ]),
                (t.wrappers = function () {
                    return [
                        t.template[0].join(this.environment),
                        t.template[1].join(this.environment),
                    ]
                })
        })),
        (wt = bt.matrix =
            v(vt, function (t, e) {
                var n = { column: "&", row: "\\\\" }
                ;(t.parentheses = { left: null, right: null }),
                    (t.environment = "matrix"),
                    (t.reflow = function () {
                        var t = this.jQ.children("table"),
                            e =
                                t.outerHeight() /
                                +t.css("fontSize").slice(0, -2),
                            n = this.jQ.children(".mq-paren")
                        n.length && X(n, Nt(1 + 0.2 * (e - 1), 1.2), 1.05 * e)
                    }),
                    (t.latex = function () {
                        var t,
                            e = ""
                        return (
                            this.eachChild(function (i) {
                                void 0 !== t &&
                                    (e += t !== i.row ? n.row : n.column),
                                    (t = i.row),
                                    (e += i.latex())
                            }),
                            this.wrappers().join(e)
                        )
                    }),
                    (t.html = function () {
                        function t(t) {
                            return t
                                ? '<span class="mq-scaled mq-paren">' +
                                      t +
                                      "</span>"
                                : ""
                        }
                        var n,
                            i = [],
                            s = "",
                            r = 0
                        return (
                            this.eachChild(function (t) {
                                n !== t.row &&
                                    ((n = t.row),
                                    (s += "<tr>$tds</tr>"),
                                    (i[n] = [])),
                                    i[n].push("<td>&" + r++ + "</td>")
                            }),
                            (this.htmlTemplate =
                                '<span class="mq-matrix mq-non-leaf">' +
                                t(this.parentheses.left) +
                                '<table class="mq-non-leaf">' +
                                s.replace(/\$tds/g, function () {
                                    return i.shift().join("")
                                }) +
                                "</table>" +
                                t(this.parentheses.right) +
                                "</span>"),
                            e.html.call(this)
                        )
                    }),
                    (t.createBlocks = function () {
                        this.blocks = [
                            xt(0, this),
                            xt(0, this),
                            xt(1, this),
                            xt(1, this),
                        ]
                    }),
                    (t.parser = function () {
                        var t = this,
                            e = M.optWhitespace,
                            i = M.string
                        return e
                            .then(i(n.column).or(i(n.row)).or(F.block))
                            .many()
                            .skip(e)
                            .then(function (e) {
                                function i() {
                                    t.blocks.push(xt(o, t, r)), (r = [])
                                }
                                var s,
                                    r = [],
                                    o = 0
                                for (t.blocks = [], s = 0; s < e.length; s += 1)
                                    e[s] instanceof V
                                        ? r.push(e[s])
                                        : (i(), e[s] === n.row && (o += 1))
                                return i(), t.autocorrect(), M.succeed(t)
                            })
                    }),
                    (t.finalizeTree = function () {
                        var t = this.jQ.find("table")
                        t.toggleClass("mq-rows-1", 1 === t.find("tr").length),
                            this.relink()
                    }),
                    (t.getEntryPoint = function (t, e, n) {
                        return "up" === n
                            ? t === w
                                ? this.blocks[this.rowSize - 1]
                                : this.blocks[0]
                            : t === w
                            ? this.blocks[this.blocks.length - 1]
                            : this.blocks[this.blocks.length - this.rowSize]
                    }),
                    (t.atExitPoint = function (t, e) {
                        var n = this.blocks.indexOf(e.parent)
                        return t === w
                            ? n % this.rowSize == 0
                            : (n + 1) % this.rowSize == 0
                    }),
                    (t.moveTowards = function (t, e, n) {
                        var i = n && this.getEntryPoint(t, e, n)
                        e.insAtDirEnd(-t, i || this.ends[-t])
                    }),
                    (t.relink = function () {
                        var t,
                            e,
                            n,
                            i,
                            s = this.blocks,
                            r = []
                        for (
                            this.rowSize = s.length, i = 0;
                            i < s.length;
                            i += 1
                        )
                            (n = s[i]),
                                t !== n.row &&
                                    (1 === n.row && (this.rowSize = e),
                                    (t = n.row),
                                    (r[t] = []),
                                    (e = 0)),
                                (r[t][e] = n),
                                (n[x] = s[i + 1]),
                                (n[w] = s[i - 1]),
                                r[t - 1] &&
                                    r[t - 1][e] &&
                                    ((n.upOutOf = r[t - 1][e]),
                                    (r[t - 1][e].downOutOf = n)),
                                (e += 1)
                        ;(this.ends[w] = s[0]), (this.ends[x] = s[s.length - 1])
                    }),
                    (t.autocorrect = function (t) {
                        var e,
                            n,
                            i,
                            s,
                            r,
                            o,
                            a = []
                        for (
                            t = [], e = this.blocks, o = 0;
                            o < e.length;
                            o += 1
                        )
                            (r = e[o].row),
                                (t[r] = t[r] || []),
                                t[r].push(e[o]),
                                (a[r] = t[r].length)
                        if (
                            (n = Math.max.apply(null, a)) !==
                            Math.min.apply(null, a)
                        ) {
                            for (o = 0; o < t.length; o += 1)
                                for (i = n - t[o].length; i; )
                                    (s = n * o + t[o].length),
                                        e.splice(s, 0, xt(o, this)),
                                        (i -= 1)
                            this.relink()
                        }
                    }),
                    (t.deleteCell = function (t) {
                        function e(t) {
                            var e,
                                n = []
                            for (e = 0; e < t.length; e += 1)
                                t[e].isEmpty() && n.push(t[e])
                            return n.length === t.length
                        }
                        function n(t) {
                            for (var e = 0; e < t.length; e += 1)
                                c.indexOf(t[e]) > -1 &&
                                    (t[e].remove(),
                                    c.splice(c.indexOf(t[e]), 1))
                        }
                        var i,
                            s,
                            r = [],
                            o = [],
                            a = [],
                            l = [],
                            c = this.blocks
                        this.eachChild(function (e) {
                            i !== e.row && ((i = e.row), (r[i] = []), (s = 0)),
                                (o[s] = o[s] || []),
                                o[s].push(e),
                                r[i].push(e),
                                e === t && ((a = r[i]), (l = o[s])),
                                (s += 1)
                        }),
                            e(a) &&
                                l.length > 1 &&
                                ((i = r.indexOf(a)),
                                this.eachChild(function (t) {
                                    t.row > i && (t.row -= 1)
                                }),
                                n(a),
                                this.jQ.find("tr").eq(i).remove()),
                            e(l) && a.length > 1 && n(l),
                            this.finalizeTree()
                    }),
                    (t.backspace = function (t, e, n, i) {
                        var s = t[e]
                        if (t.isEmpty()) {
                            for (
                                this.deleteCell(t);
                                s && s[e] && -1 === this.blocks.indexOf(s);

                            )
                                s = s[e]
                            s && n.insAtDirEnd(-e, s),
                                1 === this.blocks.length &&
                                    this.blocks[0].isEmpty() &&
                                    (i(), this.finalizeTree()),
                                this.bubble("edited")
                        }
                    })
            })),
        (bt.pmatrix = v(wt, function (t, e) {
            ;(t.environment = "pmatrix"),
                (t.parentheses = { left: "(", right: ")" })
        })),
        (bt.bmatrix = v(wt, function (t, e) {
            ;(t.environment = "bmatrix"),
                (t.parentheses = { left: "[", right: "]" })
        })),
        (bt.Bmatrix = v(wt, function (t, e) {
            ;(t.environment = "Bmatrix"),
                (t.parentheses = { left: "{", right: "}" })
        })),
        (bt.vmatrix = v(wt, function (t, e) {
            ;(t.environment = "vmatrix"),
                (t.parentheses = { left: "|", right: "|" })
        })),
        (bt.Vmatrix = v(wt, function (t, e) {
            ;(t.environment = "Vmatrix"),
                (t.parentheses = { left: "&#8214;", right: "&#8214;" })
        })),
        (xt = v(V, function (t, e) {
            ;(t.init = function (t, n, i) {
                if (
                    (e.init.call(this),
                    (this.row = t),
                    n && this.adopt(n, n.ends[x], 0),
                    i)
                )
                    for (var s = 0; s < i.length; s++)
                        i[s].children().adopt(this, this.ends[x], 0)
            }),
                (t.deleteOutOf = function (t, n) {
                    var i = this,
                        s = arguments
                    this.parent.backspace(this, t, n, function () {
                        return e.deleteOutOf.apply(i, s)
                    })
                }),
                (t.moveOutOf = function (t, e, n) {
                    ;(n && this.parent.atExitPoint(t, e)) || !this[t]
                        ? e.insDirOf(t, this.parent)
                        : e.insAtDirEnd(-t, this[t])
                })
        })),
        (j.notin =
            j.cong =
            j.equiv =
            j.oplus =
            j.otimes =
                v(U, function (t, e) {
                    t.init = function (t) {
                        e.init.call(this, "\\" + t + " ", "&" + t + ";")
                    }
                })),
        (j["≠"] = j.ne = j.neq = i(U, "\\ne ", "&ne;")),
        (j["∗"] =
            j.ast =
            j.star =
            j.loast =
            j.lowast =
                i(U, "\\ast ", "&lowast;")),
        (j.therefor = j.therefore = i(U, "\\therefore ", "&there4;")),
        (j.cuz = j.because = i(U, "\\because ", "&#8757;")),
        (j.prop = j.propto = i(U, "\\propto ", "&prop;")),
        (j["≈"] = j.asymp = j.approx = i(U, "\\approx ", "&asymp;")),
        (j.isin = j.in = i(U, "\\in ", "&isin;")),
        (j.ni = j.contains = i(U, "\\ni ", "&ni;")),
        (j.notni =
            j.niton =
            j.notcontains =
            j.doesnotcontain =
                i(U, "\\not\\ni ", "&#8716;")),
        (j.sub = j.subset = i(U, "\\subset ", "&sub;")),
        (j.sup = j.supset = j.superset = i(U, "\\supset ", "&sup;")),
        (j.nsub =
            j.notsub =
            j.nsubset =
            j.notsubset =
                i(U, "\\not\\subset ", "&#8836;")),
        (j.nsup =
            j.notsup =
            j.nsupset =
            j.notsupset =
            j.nsuperset =
            j.notsuperset =
                i(U, "\\not\\supset ", "&#8837;")),
        (j.sube =
            j.subeq =
            j.subsete =
            j.subseteq =
                i(U, "\\subseteq ", "&sube;")),
        (j.supe =
            j.supeq =
            j.supsete =
            j.supseteq =
            j.supersete =
            j.superseteq =
                i(U, "\\supseteq ", "&supe;")),
        (j.nsube =
            j.nsubeq =
            j.notsube =
            j.notsubeq =
            j.nsubsete =
            j.nsubseteq =
            j.notsubsete =
            j.notsubseteq =
                i(U, "\\not\\subseteq ", "&#8840;")),
        (j.nsupe =
            j.nsupeq =
            j.notsupe =
            j.notsupeq =
            j.nsupsete =
            j.nsupseteq =
            j.notsupsete =
            j.notsupseteq =
            j.nsupersete =
            j.nsuperseteq =
            j.notsupersete =
            j.notsuperseteq =
                i(U, "\\not\\supseteq ", "&#8841;")),
        (j.mathbb = v(P, function (e) {
            ;(e.createLeftOf = t),
                (e.numBlocks = function () {
                    return 1
                }),
                (e.parser = function () {
                    var t = M.string,
                        e = M.regex,
                        n = M.optWhitespace
                    return n
                        .then(t("{"))
                        .then(n)
                        .then(e(/^[NPZQRCH]/))
                        .skip(n)
                        .skip(t("}"))
                        .map(function (t) {
                            return j[t]()
                        })
                })
        })),
        (j.N = j.naturals = j.Naturals = i(W, "\\mathbb{N}", "&#8469;")),
        (j.P =
            j.primes =
            j.Primes =
            j.projective =
            j.Projective =
            j.probability =
            j.Probability =
                i(W, "\\mathbb{P}", "&#8473;")),
        (j.Z = j.integers = j.Integers = i(W, "\\mathbb{Z}", "&#8484;")),
        (j.Q = j.rationals = j.Rationals = i(W, "\\mathbb{Q}", "&#8474;")),
        (j.R = j.reals = j.Reals = i(W, "\\mathbb{R}", "&#8477;")),
        (j.C =
            j.complex =
            j.Complex =
            j.complexes =
            j.Complexes =
            j.complexplane =
            j.Complexplane =
            j.ComplexPlane =
                i(W, "\\mathbb{C}", "&#8450;")),
        (j.H =
            j.Hamiltonian =
            j.quaternions =
            j.Quaternions =
                i(W, "\\mathbb{H}", "&#8461;")),
        (j.quad = j.emsp = i(W, "\\quad ", "    ")),
        (j.qquad = i(W, "\\qquad ", "        ")),
        (j.diamond = i(W, "\\diamond ", "&#9671;")),
        (j.bigtriangleup = i(W, "\\bigtriangleup ", "&#9651;")),
        (j.ominus = i(W, "\\ominus ", "&#8854;")),
        (j.uplus = i(W, "\\uplus ", "&#8846;")),
        (j.bigtriangledown = i(W, "\\bigtriangledown ", "&#9661;")),
        (j.sqcap = i(W, "\\sqcap ", "&#8851;")),
        (j.triangleleft = i(W, "\\triangleleft ", "&#8882;")),
        (j.sqcup = i(W, "\\sqcup ", "&#8852;")),
        (j.triangleright = i(W, "\\triangleright ", "&#8883;")),
        (j.odot = j.circledot = i(W, "\\odot ", "&#8857;")),
        (j.bigcirc = i(W, "\\bigcirc ", "&#9711;")),
        (j.dagger = i(W, "\\dagger ", "&#0134;")),
        (j.ddagger = i(W, "\\ddagger ", "&#135;")),
        (j.wr = i(W, "\\wr ", "&#8768;")),
        (j.amalg = i(W, "\\amalg ", "&#8720;")),
        (j.models = i(W, "\\models ", "&#8872;")),
        (j.prec = i(W, "\\prec ", "&#8826;")),
        (j.succ = i(W, "\\succ ", "&#8827;")),
        (j.preceq = i(W, "\\preceq ", "&#8828;")),
        (j.succeq = i(W, "\\succeq ", "&#8829;")),
        (j.simeq = i(W, "\\simeq ", "&#8771;")),
        (j.mid = i(W, "\\mid ", "&#8739;")),
        (j.ll = i(W, "\\ll ", "&#8810;")),
        (j.gg = i(W, "\\gg ", "&#8811;")),
        (j.parallel = i(W, "\\parallel ", "&#8741;")),
        (j.nparallel = i(W, "\\nparallel ", "&#8742;")),
        (j.bowtie = i(W, "\\bowtie ", "&#8904;")),
        (j.sqsubset = i(W, "\\sqsubset ", "&#8847;")),
        (j.sqsupset = i(W, "\\sqsupset ", "&#8848;")),
        (j.smile = i(W, "\\smile ", "&#8995;")),
        (j.sqsubseteq = i(W, "\\sqsubseteq ", "&#8849;")),
        (j.sqsupseteq = i(W, "\\sqsupseteq ", "&#8850;")),
        (j.doteq = i(W, "\\doteq ", "&#8784;")),
        (j.frown = i(W, "\\frown ", "&#8994;")),
        (j.vdash = i(W, "\\vdash ", "&#8870;")),
        (j.dashv = i(W, "\\dashv ", "&#8867;")),
        (j.nless = i(W, "\\nless ", "&#8814;")),
        (j.ngtr = i(W, "\\ngtr ", "&#8815;")),
        (j.longleftarrow = i(W, "\\longleftarrow ", "&#8592;")),
        (j.longrightarrow = i(W, "\\longrightarrow ", "&#8594;")),
        (j.Longleftarrow = i(W, "\\Longleftarrow ", "&#8656;")),
        (j.Longrightarrow = i(W, "\\Longrightarrow ", "&#8658;")),
        (j.longleftrightarrow = i(W, "\\longleftrightarrow ", "&#8596;")),
        (j.updownarrow = i(W, "\\updownarrow ", "&#8597;")),
        (j.Longleftrightarrow = i(W, "\\Longleftrightarrow ", "&#8660;")),
        (j.Updownarrow = i(W, "\\Updownarrow ", "&#8661;")),
        (j.mapsto = i(W, "\\mapsto ", "&#8614;")),
        (j.nearrow = i(W, "\\nearrow ", "&#8599;")),
        (j.hookleftarrow = i(W, "\\hookleftarrow ", "&#8617;")),
        (j.hookrightarrow = i(W, "\\hookrightarrow ", "&#8618;")),
        (j.searrow = i(W, "\\searrow ", "&#8600;")),
        (j.leftharpoonup = i(W, "\\leftharpoonup ", "&#8636;")),
        (j.rightharpoonup = i(W, "\\rightharpoonup ", "&#8640;")),
        (j.swarrow = i(W, "\\swarrow ", "&#8601;")),
        (j.leftharpoondown = i(W, "\\leftharpoondown ", "&#8637;")),
        (j.rightharpoondown = i(W, "\\rightharpoondown ", "&#8641;")),
        (j.nwarrow = i(W, "\\nwarrow ", "&#8598;")),
        (j.ldots = i(W, "\\ldots ", "&#8230;")),
        (j.cdots = i(W, "\\cdots ", "&#8943;")),
        (j.vdots = i(W, "\\vdots ", "&#8942;")),
        (j.ddots = i(W, "\\ddots ", "&#8945;")),
        (j.surd = i(W, "\\surd ", "&#8730;")),
        (j.triangle = i(W, "\\triangle ", "&#9651;")),
        (j.ell = i(W, "\\ell ", "&#8467;")),
        (j.top = i(W, "\\top ", "&#8868;")),
        (j.flat = i(W, "\\flat ", "&#9837;")),
        (j.natural = i(W, "\\natural ", "&#9838;")),
        (j.sharp = i(W, "\\sharp ", "&#9839;")),
        (j.wp = i(W, "\\wp ", "&#8472;")),
        (j.bot = i(W, "\\bot ", "&#8869;")),
        (j.clubsuit = i(W, "\\clubsuit ", "&#9827;")),
        (j.diamondsuit = i(W, "\\diamondsuit ", "&#9826;")),
        (j.heartsuit = i(W, "\\heartsuit ", "&#9825;")),
        (j.spadesuit = i(W, "\\spadesuit ", "&#9824;")),
        (j.parallelogram = i(W, "\\parallelogram ", "&#9649;")),
        (j.square = i(W, "\\square ", "&#11036;")),
        (j.oint = i(W, "\\oint ", "&#8750;")),
        (j.bigcap = i(W, "\\bigcap ", "&#8745;")),
        (j.bigcup = i(W, "\\bigcup ", "&#8746;")),
        (j.bigsqcup = i(W, "\\bigsqcup ", "&#8852;")),
        (j.bigvee = i(W, "\\bigvee ", "&#8744;")),
        (j.bigwedge = i(W, "\\bigwedge ", "&#8743;")),
        (j.bigodot = i(W, "\\bigodot ", "&#8857;")),
        (j.bigotimes = i(W, "\\bigotimes ", "&#8855;")),
        (j.bigoplus = i(W, "\\bigoplus ", "&#8853;")),
        (j.biguplus = i(W, "\\biguplus ", "&#8846;")),
        (j.lfloor = i(W, "\\lfloor ", "&#8970;")),
        (j.rfloor = i(W, "\\rfloor ", "&#8971;")),
        (j.lceil = i(W, "\\lceil ", "&#8968;")),
        (j.rceil = i(W, "\\rceil ", "&#8969;")),
        (j.opencurlybrace = j.lbrace = i(W, "\\lbrace ", "{")),
        (j.closecurlybrace = j.rbrace = i(W, "\\rbrace ", "}")),
        (j.lbrack = i(W, "[")),
        (j.rbrack = i(W, "]")),
        (j.slash = i(W, "/")),
        (j.vert = i(W, "|")),
        (j.perp = j.perpendicular = i(W, "\\perp ", "&perp;")),
        (j.nabla = j.del = i(W, "\\nabla ", "&nabla;")),
        (j.hbar = i(W, "\\hbar ", "&#8463;")),
        (j.AA = j.Angstrom = j.angstrom = i(W, "\\text\\AA ", "&#8491;")),
        (j.ring = j.circ = j.circle = i(W, "\\circ ", "&#8728;")),
        (j.bull = j.bullet = i(W, "\\bullet ", "&bull;")),
        (j.setminus = j.smallsetminus = i(W, "\\setminus ", "&#8726;")),
        (j.not = j["¬"] = j.neg = i(W, "\\neg ", "&not;")),
        (j["…"] =
            j.dots =
            j.ellip =
            j.hellip =
            j.ellipsis =
            j.hellipsis =
                i(W, "\\dots ", "&hellip;")),
        (j.converges =
            j.darr =
            j.dnarr =
            j.dnarrow =
            j.downarrow =
                i(W, "\\downarrow ", "&darr;")),
        (j.dArr =
            j.dnArr =
            j.dnArrow =
            j.Downarrow =
                i(W, "\\Downarrow ", "&dArr;")),
        (j.diverges = j.uarr = j.uparrow = i(W, "\\uparrow ", "&uarr;")),
        (j.uArr = j.Uparrow = i(W, "\\Uparrow ", "&uArr;")),
        (j.to = i(U, "\\to ", "&rarr;")),
        (j.rarr = j.rightarrow = i(W, "\\rightarrow ", "&rarr;")),
        (j.implies = i(U, "\\Rightarrow ", "&rArr;")),
        (j.rArr = j.Rightarrow = i(W, "\\Rightarrow ", "&rArr;")),
        (j.gets = i(U, "\\gets ", "&larr;"))
    ;(j.larr = j.leftarrow = i(W, "\\leftarrow ", "&larr;")),
        (j.impliedby = i(U, "\\Leftarrow ", "&lArr;")),
        (j.lArr = j.Leftarrow = i(W, "\\Leftarrow ", "&lArr;")),
        (j.harr =
            j.lrarr =
            j.leftrightarrow =
                i(W, "\\leftrightarrow ", "&harr;")),
        (j.iff = i(U, "\\Leftrightarrow ", "&hArr;")),
        (j.hArr =
            j.lrArr =
            j.Leftrightarrow =
                i(W, "\\Leftrightarrow ", "&hArr;")),
        (j.Re = j.Real = j.real = i(W, "\\Re ", "&real;")),
        (j.Im =
            j.imag =
            j.image =
            j.imagin =
            j.imaginary =
            j.Imaginary =
                i(W, "\\Im ", "&image;")),
        (j.part = j.partial = i(W, "\\partial ", "&part;")),
        (j.infty = j.infin = j.infinity = i(W, "\\infty ", "&infin;")),
        (j.pounds = i(W, "\\pounds ", "&pound;")),
        (j.alef =
            j.alefsym =
            j.aleph =
            j.alephsym =
                i(W, "\\aleph ", "&alefsym;")),
        (j.xist = j.xists = j.exist = j.exists = i(W, "\\exists ", "&exist;")),
        (j.nexists = j.nexist = i(W, "\\nexists ", "&#8708;")),
        (j.and = j.land = j.wedge = i(U, "\\wedge ", "&and;")),
        (j.or = j.lor = j.vee = i(U, "\\vee ", "&or;")),
        (j.o =
            j.O =
            j.empty =
            j.emptyset =
            j.oslash =
            j.Oslash =
            j.nothing =
            j.varnothing =
                i(U, "\\varnothing ", "&empty;")),
        (j.cup = j.union = i(U, "\\cup ", "&cup;")),
        (j.cap = j.intersect = j.intersection = i(U, "\\cap ", "&cap;")),
        (j.deg = j.degree = i(W, "\\degree ", "&deg;")),
        (j.ang = j.angle = i(W, "\\angle ", "&ang;")),
        (j.measuredangle = i(W, "\\measuredangle ", "&#8737;")),
        (qt = v(W, function (t, e) {
            t.createLeftOf = function (t) {
                t.options.autoSubscriptNumerals &&
                t.parent !== t.parent.parent.sub &&
                ((t[w] instanceof yt && !1 !== t[w].isItalic) ||
                    (t[w] instanceof ot &&
                        t[w][w] instanceof yt &&
                        !1 !== t[w][w].isItalic))
                    ? (j._().createLeftOf(t),
                      e.createLeftOf.call(this, t),
                      t.insRightOf(t.parent.parent))
                    : e.createLeftOf.call(this, t)
            }
        })),
        (yt = v($, function (t, e) {
            ;(t.init = function (t, n) {
                e.init.call(this, t, "<var>" + (n || t) + "</var>")
            }),
                (t.text = function () {
                    var t = this.ctrlSeq
                    return (
                        this.isPartOfOperator
                            ? "\\" == t[0]
                                ? (t = t.slice(1, t.length))
                                : " " == t[t.length - 1] && (t = t.slice(0, -1))
                            : (!this[w] ||
                                  this[w] instanceof yt ||
                                  this[w] instanceof U ||
                                  "\\ " === this[w].ctrlSeq ||
                                  (t = "*" + t),
                              !this[x] ||
                                  this[x] instanceof U ||
                                  this[x] instanceof ot ||
                                  (t += "*")),
                        t
                    )
                })
        })),
        (S.p.autoCommands = { _maxLength: 0 }),
        (E.autoCommands = function (t) {
            var e, n, i, s, r
            if (!/^[a-z]+(?: [a-z]+)*$/i.test(t))
                throw '"' + t + '" not a space-delimited list of only letters'
            for (e = t.split(" "), n = {}, i = 0, s = 0; s < e.length; s += 1) {
                if (((r = e[s]), r.length < 2))
                    throw 'autocommand "' + r + '" not minimum length of 2'
                if (j[r] === Tt) throw '"' + r + '" is a built-in operator name'
                ;(n[r] = 1), (i = Pt(i, r.length))
            }
            return (n._maxLength = i), n
        }),
        (Ot = v(yt, function (t, e) {
            function n(t) {
                return !t || t instanceof U || t instanceof at
            }
            ;(t.init = function (t) {
                return e.init.call(this, (this.letter = t))
            }),
                (t.createLeftOf = function (t) {
                    var n, i, s, r, o
                    if (
                        (e.createLeftOf.apply(this, arguments),
                        (n = t.options.autoCommands),
                        (i = n._maxLength),
                        i > 0)
                    ) {
                        for (
                            s = "", r = this, o = 0;
                            r instanceof Ot && r.ctrlSeq === r.letter && o < i;

                        )
                            (s = r.letter + s), (r = r[w]), (o += 1)
                        for (; s.length; ) {
                            if (n.hasOwnProperty(s)) {
                                for (
                                    o = 1, r = this;
                                    o < s.length;
                                    o += 1, r = r[w]
                                );
                                return (
                                    k(r, this).remove(),
                                    (t[w] = r[w]),
                                    j[s](s).createLeftOf(t)
                                )
                            }
                            s = s.slice(1)
                        }
                    }
                }),
                (t.italicize = function (t) {
                    return (
                        (this.isItalic = t),
                        (this.isPartOfOperator = !t),
                        this.jQ.toggleClass("mq-operator-name", !t),
                        this
                    )
                }),
                (t.finalizeTree =
                    t.siblingDeleted =
                    t.siblingCreated =
                        function (t, e) {
                            ;(e !== w && this[x] instanceof Ot) ||
                                this.autoUnItalicize(t)
                        }),
                (t.autoUnItalicize = function (t) {
                    var e,
                        i,
                        s,
                        r,
                        o,
                        a,
                        l,
                        c,
                        h,
                        u,
                        f,
                        p,
                        d = t.autoOperatorNames
                    if (0 !== d._maxLength) {
                        for (
                            e = this.letter, i = this[w];
                            i instanceof Ot;
                            i = i[w]
                        )
                            e = i.letter + e
                        for (s = this[x]; s instanceof Ot; s = s[x])
                            e += s.letter
                        k(
                            i[x] || this.parent.ends[w],
                            s[w] || this.parent.ends[x]
                        ).each(function (t) {
                            t
                                .italicize(!0)
                                .jQ.removeClass(
                                    "mq-first mq-last mq-followed-by-supsub"
                                ),
                                (t.ctrlSeq = t.letter)
                        })
                        t: for (
                            r = 0, o = i[x] || this.parent.ends[w];
                            r < e.length;
                            r += 1, o = o[x]
                        )
                            for (
                                a = Nt(d._maxLength, e.length - r);
                                a > 0;
                                a -= 1
                            )
                                if (
                                    ((l = e.slice(r, r + a)),
                                    d.hasOwnProperty(l))
                                ) {
                                    for (c = 0, h = o; c < a; c += 1, h = h[x])
                                        h.italicize(!1), (u = h)
                                    ;(f = kt.hasOwnProperty(l)),
                                        (o.ctrlSeq =
                                            (f ? "\\" : "\\operatorname{") +
                                            o.ctrlSeq),
                                        (u.ctrlSeq += f ? " " : "}"),
                                        Qt.hasOwnProperty(l) &&
                                            u[w][w][w].jQ.addClass("mq-last"),
                                        n(o[w]) || o.jQ.addClass("mq-first"),
                                        n(u[x]) ||
                                            (u[x] instanceof ot
                                                ? ((p = u[x]),
                                                  (p.siblingCreated =
                                                      p.siblingDeleted =
                                                          function () {
                                                              p.jQ.toggleClass(
                                                                  "mq-after-operator-name",
                                                                  !(
                                                                      p[
                                                                          x
                                                                      ] instanceof
                                                                      pt
                                                                  )
                                                              )
                                                          })())
                                                : u.jQ.toggleClass(
                                                      "mq-last",
                                                      !(u[x] instanceof pt)
                                                  )),
                                        (r += a - 1),
                                        (o = u)
                                    continue t
                                }
                    }
                })
        })),
        (kt = {}),
        (jt = S.p.autoOperatorNames = { _maxLength: 9 }),
        (Qt = { limsup: 1, liminf: 1, projlim: 1, injlim: 1 }),
        (function () {
            var t,
                e,
                n,
                i,
                s =
                    "arg deg det dim exp gcd hom inf ker lg lim ln log max min sup limsup liminf injlim projlim Pr".split(
                        " "
                    )
            for (t = 0; t < s.length; t += 1) kt[s[t]] = jt[s[t]] = 1
            for (
                e =
                    "sin cos tan arcsin arccos arctan sinh cosh tanh sec csc cot coth".split(
                        " "
                    ),
                    t = 0;
                t < e.length;
                t += 1
            )
                kt[e[t]] = 1
            for (
                n = "sin cos tan sec cosec csc cotan cot ctg".split(" "), t = 0;
                t < n.length;
                t += 1
            )
                jt[n[t]] =
                    jt["arc" + n[t]] =
                    jt[n[t] + "h"] =
                    jt["ar" + n[t] + "h"] =
                    jt["arc" + n[t] + "h"] =
                        1
            for (
                i = "gcf hcf lcm proj span".split(" "), t = 0;
                t < i.length;
                t += 1
            )
                jt[i[t]] = 1
        })(),
        (E.autoOperatorNames = function (t) {
            var e, n, i, s, r
            if (!/^[a-z]+(?: [a-z]+)*$/i.test(t))
                throw '"' + t + '" not a space-delimited list of only letters'
            for (e = t.split(" "), n = {}, i = 0, s = 0; s < e.length; s += 1) {
                if (((r = e[s]), r.length < 2))
                    throw '"' + r + '" not minimum length of 2'
                ;(n[r] = 1), (i = Pt(i, r.length))
            }
            return (n._maxLength = i), n
        }),
        (Tt = v($, function (t, e) {
            ;(t.init = function (t) {
                this.ctrlSeq = t
            }),
                (t.createLeftOf = function (t) {
                    var e,
                        n = this.ctrlSeq
                    for (e = 0; e < n.length; e += 1)
                        Ot(n.charAt(e)).createLeftOf(t)
                }),
                (t.parser = function () {
                    var t,
                        e = this.ctrlSeq,
                        n = V()
                    for (t = 0; t < e.length; t += 1)
                        Ot(e.charAt(t)).adopt(n, n.ends[x], 0)
                    return M.succeed(n.children())
                })
        }))
    for (_t in jt) jt.hasOwnProperty(_t) && (j[_t] = Tt)
    ;(j.operatorname = v(P, function (e) {
        ;(e.createLeftOf = t),
            (e.numBlocks = function () {
                return 1
            }),
            (e.parser = function () {
                return F.block.map(function (t) {
                    return t.children()
                })
            })
    })),
        (j.f = v(Ot, function (t, e) {
            ;(t.init = function () {
                $.p.init.call(
                    this,
                    (this.letter = "f"),
                    '<var class="mq-f">f</var>'
                )
            }),
                (t.italicize = function (t) {
                    return (
                        this.jQ.html("f").toggleClass("mq-f", t),
                        e.italicize.apply(this, arguments)
                    )
                })
        })),
        (j[" "] = j.space = i(W, "\\ ", "&nbsp;")),
        (j["'"] = j.prime = i(W, "'", "&prime;")),
        (j["″"] = j.dprime = i(W, "″", "&Prime;")),
        (j.backslash = i(W, "\\backslash ", "\\")),
        Q["\\"] || (Q["\\"] = j.backslash),
        (j.$ = i(W, "\\$", "$")),
        (Ct = v($, function (t, e) {
            t.init = function (t, n) {
                e.init.call(
                    this,
                    t,
                    '<span class="mq-nonSymbola">' + (n || t) + "</span>"
                )
            }
        })),
        (j["@"] = Ct),
        (j["&"] = i(Ct, "\\&", "&amp;")),
        (j["%"] = i(Ct, "\\%", "%")),
        (j.alpha =
            j.beta =
            j.gamma =
            j.delta =
            j.zeta =
            j.eta =
            j.theta =
            j.iota =
            j.kappa =
            j.mu =
            j.nu =
            j.xi =
            j.rho =
            j.sigma =
            j.tau =
            j.chi =
            j.psi =
            j.omega =
                v(yt, function (t, e) {
                    t.init = function (t) {
                        e.init.call(this, "\\" + t + " ", "&" + t + ";")
                    }
                })),
        (j.phi = i(yt, "\\phi ", "&#981;")),
        (j.phiv = j.varphi = i(yt, "\\varphi ", "&phi;")),
        (j.epsilon = i(yt, "\\epsilon ", "&#1013;")),
        (j.epsiv = j.varepsilon = i(yt, "\\varepsilon ", "&epsilon;")),
        (j.piv = j.varpi = i(yt, "\\varpi ", "&piv;")),
        (j.sigmaf = j.sigmav = j.varsigma = i(yt, "\\varsigma ", "&sigmaf;")),
        (j.thetav =
            j.vartheta =
            j.thetasym =
                i(yt, "\\vartheta ", "&thetasym;")),
        (j.upsilon = j.upsi = i(yt, "\\upsilon ", "&upsilon;")),
        (j.gammad = j.Gammad = j.digamma = i(yt, "\\digamma ", "&#989;")),
        (j.kappav = j.varkappa = i(yt, "\\varkappa ", "&#1008;")),
        (j.rhov = j.varrho = i(yt, "\\varrho ", "&#1009;")),
        (j.pi = j["π"] = i(Ct, "\\pi ", "&pi;")),
        (j.lambda = i(Ct, "\\lambda ", "&lambda;")),
        (j.Upsilon =
            j.Upsi =
            j.upsih =
            j.Upsih =
                i(
                    $,
                    "\\Upsilon ",
                    '<var style="font-family: serif">&upsih;</var>'
                )),
        (j.Gamma =
            j.Delta =
            j.Theta =
            j.Lambda =
            j.Xi =
            j.Pi =
            j.Sigma =
            j.Phi =
            j.Psi =
            j.Omega =
            j.forall =
                v(W, function (t, e) {
                    t.init = function (t) {
                        e.init.call(this, "\\" + t + " ", "&" + t + ";")
                    }
                })),
        (Dt = v(P, function (t) {
            ;(t.init = function (t) {
                this.latex = t
            }),
                (t.createLeftOf = function (t) {
                    var e = F.parse(this.latex)
                    e.children().adopt(t.parent, t[w], t[x]),
                        (t[w] = e.ends[x]),
                        e.jQize().insertBefore(t.jQ),
                        e.finalizeInsert(t.options, t),
                        e.ends[x][x].siblingCreated &&
                            e.ends[x][x].siblingCreated(t.options, w),
                        e.ends[w][w].siblingCreated &&
                            e.ends[w][w].siblingCreated(t.options, x),
                        t.parent.bubble("reflow")
                }),
                (t.parser = function () {
                    var t = F.parse(this.latex).children()
                    return M.succeed(t)
                })
        })),
        (j["¹"] = i(Dt, "^1")),
        (j["²"] = i(Dt, "^2")),
        (j["³"] = i(Dt, "^3")),
        (j["¼"] = i(Dt, "\\frac14")),
        (j["½"] = i(Dt, "\\frac12")),
        (j["¾"] = i(Dt, "\\frac34")),
        (St = v(U, function (t) {
            ;(t.init = W.prototype.init),
                (t.contactWeld =
                    t.siblingCreated =
                    t.siblingDeleted =
                        function (t, e) {
                            function n(t) {
                                return t[w]
                                    ? t[w] instanceof U ||
                                      /^[,;:\(\[]$/.test(t[w].ctrlSeq)
                                        ? ""
                                        : "mq-binary-operator"
                                    : t.parent &&
                                      t.parent.parent &&
                                      t.parent.parent.isStyleBlock()
                                    ? n(t.parent.parent)
                                    : ""
                            }
                            if (e !== x)
                                return (this.jQ[0].className = n(this)), this
                        })
        })),
        (j["+"] = i(St, "+", "+")),
        (j["–"] = j["-"] = i(St, "-", "&minus;")),
        (j["±"] = j.pm = j.plusmn = j.plusminus = i(St, "\\pm ", "&plusmn;")),
        (j.mp = j.mnplus = j.minusplus = i(St, "\\mp ", "&#8723;")),
        (Q["*"] = j.sdot = j.cdot = i(U, "\\cdot ", "&middot;", "*")),
        (Et = v(U, function (t, e) {
            ;(t.init = function (t, n) {
                ;(this.data = t), (this.strict = n)
                var i = n ? "Strict" : ""
                e.init.call(
                    this,
                    t["ctrlSeq" + i],
                    t["html" + i],
                    t["text" + i]
                )
            }),
                (t.swap = function (t) {
                    this.strict = t
                    var e = t ? "Strict" : ""
                    ;(this.ctrlSeq = this.data["ctrlSeq" + e]),
                        this.jQ.html(this.data["html" + e]),
                        (this.textTemplate = [this.data["text" + e]])
                }),
                (t.deleteTowards = function (t, n) {
                    if (t === w && !this.strict)
                        return this.swap(!0), void this.bubble("reflow")
                    e.deleteTowards.apply(this, arguments)
                })
        })),
        (At = {
            ctrlSeq: "\\le ",
            html: "&le;",
            text: "≤",
            ctrlSeqStrict: "<",
            htmlStrict: "&lt;",
            textStrict: "<",
        }),
        (Lt = {
            ctrlSeq: "\\ge ",
            html: "&ge;",
            text: "≥",
            ctrlSeqStrict: ">",
            htmlStrict: "&gt;",
            textStrict: ">",
        }),
        (j["<"] = j.lt = i(Et, At, !0)),
        (j[">"] = j.gt = i(Et, Lt, !0)),
        (j["≤"] = j.le = j.leq = i(Et, At, !1)),
        (j["≥"] = j.ge = j.geq = i(Et, Lt, !1)),
        (zt = v(U, function (t, e) {
            ;(t.init = function () {
                e.init.call(this, "=", "=")
            }),
                (t.createLeftOf = function (t) {
                    if (t[w] instanceof Et && t[w].strict)
                        return t[w].swap(!1), void t[w].bubble("reflow")
                    e.createLeftOf.apply(this, arguments)
                })
        })),
        (j["="] = zt),
        (j["×"] = j.times = i(U, "\\times ", "&times;", "[x]")),
        (j["÷"] =
            j.div =
            j.divide =
            j.divides =
                i(U, "\\div ", "&divide;", "[/]")),
        (Q["~"] = j.sim = i(U, "\\sim ", "~", "~")),
        (It = c(1))
    for (Rt in It)
        !(function (t, e) {
            "function" == typeof e
                ? ((l[t] = function () {
                      return a(), e.apply(this, arguments)
                  }),
                  (l[t].prototype = e.prototype))
                : (l[t] = e)
        })(Rt, It[Rt])
})()
