import React from "react"
import "./Train.css"

export default function Train() {
    return (
      <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1196 242.32"
    className="train-svg"
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #000;\n      }\n\n      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {\n        stroke-width: 0px;\n      }\n\n      .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12 {\n        stroke: #000;\n        stroke-miterlimit: 10;\n      }\n\n      .cls-6, .cls-4 {\n        fill: #013673;\n      }\n\n      .cls-7 {\n        fill: none;\n      }\n\n      .cls-8, .cls-9, .cls-2 {\n        fill: #5c7aa2;\n      }\n\n      .cls-9 {\n        stroke-width: .75px;\n      }\n\n      .cls-10 {\n        fill: #1e1e1e;\n      }\n\n      .cls-11 {\n        fill: #c2c9dd;\n      }\n\n      .cls-11, .cls-12 {\n        stroke-width: 3px;\n      }\n\n      .cls-3 {\n        fill: #313131;\n      }\n\n      .cls-5 {\n        fill: #8e8b8b;\n      }\n\n      .cls-12 {\n        fill: #ffac05;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <polyline
        className="cls-10"
        points="37.69 182.01 20.8 208.55 52.16 230.26 224.98 230.26 254.82 208.55 242.75 184.42"
      />
      <polyline
        className="cls-10"
        points="800.05 184.42 783.16 208.55 809.7 230.26 985.05 230.26 1012.35 210.96 1000.29 184.42"
      />
      <path
        className="cls-12"
        d="M1165.55,224.23l28.95-50.66v-49.8l-36.19-82.89s-33.78-45.84-197.83-38.6L1.5,1.5v222.73h10.16l28.44-39.81h200.24l22.73,39.81h510.7l26.28-39.81h200.24l15.75,39.81h149.51Z"
      />
      <polyline
        className="cls-11"
        points="1163.23 52.15 1077.41 52.15 1077.41 100.75 1194.5 129.19"
      />
      <path
        className="cls-4"
        d="M1.5,150.22l950.54-1.99s12.06,0,16.89-16.89V53.38s.38-17.7-17.9-18.12H1.5"
      />
      <g>
        <g>
          <rect
            className="cls-3"
            x={1020.8}
            y={172.53}
            width={38.6}
            height={13.1}
          />
          <polygon
            className="cls-3"
            points="1059.4 159.33 1059.4 172.53 1020.8 172.53 1020.8 159.33 1058.7 159.33 1059.4 159.33"
          />
          <path
            className="cls-2"
            d="M1054.57,62.59v41.01s0,7.24-4.83,7.24h-16.89s-7.24,0-7.24-4.83v-43.43s0-9.65,7.24-9.65h12.64s9.07,0,9.07,9.65Z"
          />
        </g>
        <g>
          <polyline
            className="cls-7"
            points="1020.8 172.53 1020.8 185.63 1059.4 185.63 1059.4 172.53"
          />
          <path
            className="cls-7"
            d="M1058.7,159.33h-37.9v13.2h38.6V48.11c0-4.83-4.75-4.83-4.75-4.83h-29.02c-4.83,0-4.83,4.83-4.83,4.83v111.22"
          />
          <path
            className="cls-7"
            d="M1054.57,103.6v-41.01c0-9.65-9.07-9.65-9.07-9.65h-12.64c-7.24,0-7.24,9.65-7.24,9.65v43.43c0,4.83,7.24,4.83,7.24,4.83h16.89c4.83,0,4.83-7.24,4.83-7.24Z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            className="cls-3"
            points="838.02 170.43 838.02 183.63 789.77 183.63 789.77 170.43 837.32 170.43 838.02 170.43"
          />
          <path
            className="cls-2"
            d="M828.37,73.69v41.01s0,7.24-4.83,7.24h-16.89s-7.24,0-7.24-4.83v-43.43s0-.14.02-.41c.12-1.86,1.18-9.24,9.63-9.24h10.21s8.54,0,9.07,8.78c.02.27.02.58.02.87Z"
          />
          <polygon
            className="cls-3"
            points="789.77 170.43 789.77 183.63 741.52 183.63 741.52 170.43 789.07 170.43 789.77 170.43"
          />
          <path
            className="cls-2"
            d="M780.12,73.69v41.01s0,7.24-4.83,7.24h-16.89s-7.24,0-7.24-4.83v-43.43s0-.14.02-.41c.12-1.86,1.18-9.24,9.63-9.24h10.21s8.54,0,9.07,8.78c.02.27.02.58.02.87Z"
          />
        </g>
        <g>
          <path
            className="cls-7"
            d="M789.77,183.63h48.25V59.21c0-4.83-4.78-4.83-4.78-4.83h-43.43"
          />
          <line
            className="cls-7"
            x1={837.32}
            y1={170.43}
            x2={789.77}
            y2={170.43}
          />
          <path
            className="cls-7"
            d="M799.45,73.28c.1-1.83.87-9.24,7.21-9.24h2.41"
          />
          <path
            className="cls-7"
            d="M819.28,64.04h-10.21c-8.44,0-9.51,7.38-9.63,9.24"
          />
          <path
            className="cls-7"
            d="M799.45,73.28c-.02.27-.02.41-.02.41v43.43c0,4.83,7.24,4.83,7.24,4.83h16.89c4.83,0,4.83-7.24,4.83-7.24v-41.01c0-.29,0-.6-.02-.87-.39-8.78-6.66-8.78-6.66-8.78h-2.41s8.54,0,9.07,8.78"
          />
          <polyline
            className="cls-7"
            points="789.07 170.43 741.52 170.43 741.52 183.63 789.77 183.63 789.77 170.43 789.77 59.21"
          />
          <path
            className="cls-7"
            d="M789.77,54.39h-43.43c-4.83,0-4.83,4.83-4.83,4.83v111.22"
          />
          <path
            className="cls-7"
            d="M751.2,73.28c.1-1.83.87-9.24,7.21-9.24h2.41"
          />
          <path
            className="cls-7"
            d="M789.82,54.39h-.05v4.83c0-4.83.05-4.83.05-4.83Z"
          />
          <path
            className="cls-7"
            d="M771.03,64.04h-10.21c-8.44,0-9.51,7.38-9.63,9.24"
          />
          <path
            className="cls-7"
            d="M751.2,73.28c-.02.27-.02.41-.02.41v43.43c0,4.83,7.24,4.83,7.24,4.83h16.89c4.83,0,4.83-7.24,4.83-7.24v-41.01c0-.29,0-.6-.02-.87-.39-8.78-6.66-8.78-6.66-8.78h-2.41s8.54,0,9.07,8.78"
          />
        </g>
      </g>
      <g>
        <path
          className="cls-8"
          d="M567.24,85.04v28.21s0,11.38,9.65,12.93l94.09-.87s9.65,0,9.65-12.06v-28.21h-113.39Z"
        />
        <path
          className="cls-8"
          d="M680.63,76.24s.59-13.65-10.56-13.65h-90.77s-12.06,0-12.06,14.48v7.98h113.39v-8.8Z"
        />
      </g>
      <g>
        <path
          className="cls-9"
          d="M427.31,85.04v28.21s0,11.38,9.65,12.93l94.09-.87s9.65,0,9.65-12.06v-28.21h-113.39Z"
        />
        <path
          className="cls-9"
          d="M540.7,76.24s.59-13.65-10.56-13.65h-90.77s-12.06,0-12.06,14.48v7.98h113.39v-8.8Z"
        />
      </g>
      <g>
        <path
          className="cls-8"
          d="M284.97,85.04v28.21s0,11.38,9.65,12.93l94.09-.87s9.65,0,9.65-12.06v-28.21h-113.39Z"
        />
        <path
          className="cls-8"
          d="M398.36,76.24s.59-13.65-10.56-13.65h-90.77s-12.06,0-12.06,14.48v7.98h113.39v-8.8Z"
        />
      </g>
      <g>
        <path
          className="cls-8"
          d="M142.63,85.04v28.21s0,11.38,9.65,12.93l94.09-.87s9.65,0,9.65-12.06v-28.21h-113.39Z"
        />
        <path
          className="cls-8"
          d="M256.02,76.24s.59-13.65-10.56-13.65h-90.77s-12.06,0-12.06,14.48v7.98h113.39v-8.8Z"
        />
      </g>
      <g>
        <path
          className="cls-8"
          d="M2.71,85.04v28.21s0,11.38,9.65,12.93l94.09-.87s9.65,0,9.65-12.06v-28.21H2.71Z"
        />
        <path
          className="cls-8"
          d="M116.1,76.24s.59-13.65-10.56-13.65H14.77s-12.06,0-12.06,14.48v7.98h113.39v-8.8Z"
        />
      </g>
      <g className="train-wheel-svg">
        <path
          className="cls-1"
          d="M977.37,184.42c-15.99,0-28.95,12.96-28.95,28.95s12.96,28.95,28.95,28.95,28.95-12.96,28.95-28.95-12.96-28.95-28.95-28.95ZM981.5,218.32h-9v-9h9v9Z"
        />
        <rect className="cls-5" x={972.5} y={209.32} width={9} height={9} />
      </g>
      <g className="train-wheel-svg">
        <path
          className="cls-1"
          d="M822.37,184.42c-15.99,0-28.95,12.96-28.95,28.95s12.96,28.95,28.95,28.95,28.95-12.96,28.95-28.95-12.96-28.95-28.95-28.95ZM826.5,218.32h-9v-9h9v9Z"
        />
        <rect className="cls-5" x={817.5} y={209.32} width={9} height={9} />
      </g>
      <g className="train-wheel-svg">
        <path
          className="cls-1"
          d="M216.37,184.42c-15.99,0-28.95,12.96-28.95,28.95s12.96,28.95,28.95,28.95,28.95-12.96,28.95-28.95-12.96-28.95-28.95-28.95ZM220.5,218.32h-9v-9h9v9Z"
        />
        <rect className="cls-5" x={211.5} y={209.32} width={9} height={9} />
      </g>
      <g className="train-wheel-svg">
        <path
          className="cls-1"
          d="M63.37,184.42c-15.99,0-28.95,12.96-28.95,28.95s12.96,28.95,28.95,28.95,28.95-12.96,28.95-28.95-12.96-28.95-28.95-28.95ZM67.5,218.32h-9v-9h9v9Z"
        />
        <rect className="cls-5" x={58.5} y={209.32} width={9} height={9} />
      </g>
      <path
        className="cls-6"
        d="M1194.5,173.57s-20.5-11.74-72.5,29.26c0,0-26,16-105.96,21.41h149.51l28.95-50.66Z"
      />
    </g>
  </svg>
)
}
