import React from "react"
import "./TrainBackground.css"

interface Props {
    includeRail: boolean
}

export default function TrainBackground(props: Props) {
    return (
        <div className="background-train">
            {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100vw"
                height="100vh"
                preserveAspectRatio="none"
                viewBox="0 0 1440 560"
                data-testid="train-background"
            >
                <g mask='url("#SvgjsMask1056")' fill="none">
                    <rect
                        width="1440"
                        height="560"
                        x="0"
                        y="0"
                        fill="rgba(120, 66, 18, 1)"
                    ></rect>
                    <path
                        d="M0,342.793C68.92,329.535,139.801,345.066,204.891,318.816C279.715,288.64,352.831,248.475,398.607,182.038C448.823,109.157,498.18,16.553,471.246,-67.755C444.143,-152.594,324.077,-162.977,266.641,-231.046C207.974,-300.574,217.026,-422.419,136.412,-464.577C56.128,-506.562,-50.116,-483.096,-128.519,-437.697C-201.697,-395.323,-216.802,-299.372,-266.74,-231.132C-311.334,-170.194,-375.165,-127.274,-406.096,-58.388C-444.812,27.836,-493.84,122.507,-466.104,212.863C-437.91,304.714,-355.251,382.967,-262.699,408.767C-173.931,433.512,-90.493,360.201,0,342.793"
                        fill="#4e2b0c"
                    ></path>
                    <path
                        d="M1440 880.571C1498.883 873.9590000000001 1546.766 835.6089999999999 1595.061 801.279 1641.223 768.465 1685.452 733.97 1714.5720000000001 685.393 1746.1680000000001 632.687 1779.144 573.271 1767.967 512.845 1756.871 452.85699999999997 1697.452 417.625 1656.653 372.269 1616.718 327.874 1588.868 264.881 1531.149 249.57600000000002 1473.605 234.317 1416.342 269.964 1361.93 294.119 1313.754 315.506 1275.479 348.97900000000004 1233.99 381.491 1181.897 422.312 1112.226 447.971 1087.339 509.295 1060.845 574.579 1065.377 653.752 1098.199 716.095 1130.488 777.4259999999999 1198.363 808.755 1260.82 838.81 1317.224 865.952 1377.796 887.556 1440 880.571"
                        fill="#a25918"
                    ></path>
                </g>
                <defs>
                    <mask id="SvgjsMask1056">
                        <rect width="1440" height="560" fill="#ffffff"></rect>
                    </mask>
                </defs>
            </svg> */}
            {props.includeRail ? 
                <div>
                    <div className="sliding-background-clouds"></div>
                    <div className="background-train-track"></div>
                </div> : null}
        </div>
    )
}
